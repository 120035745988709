import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  width: "24",
  height: "24",
  viewBox: "0 0 24 24",
  fill: "none",
  xmlns: "http://www.w3.org/2000/svg"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createElementVNode("path", {
      d: "M3.75 7.90628C3.75 7.1909 4.1321 6.52999 4.75207 6.17304L11.0021 2.57456C11.6198 2.21888 12.3802 2.21888 12.9979 2.57456L19.2479 6.17304C19.8679 6.52999 20.25 7.1909 20.25 7.90629V18.25C20.25 19.3546 19.3546 20.25 18.25 20.25H5.75C4.64543 20.25 3.75 19.3546 3.75 18.25V7.90628Z",
      stroke: "#FF0000",
      "stroke-width": "1.5",
      "stroke-linejoin": "round"
    }, null, -1),
    _createElementVNode("path", {
      d: "M7.95135 16.25L15.9761 16.25",
      stroke: "#FF0000",
      "stroke-width": "1.5",
      "stroke-linecap": "round"
    }, null, -1)
  ])))
}
export default { render: render }