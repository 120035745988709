export default {
  install: (app) => {
    const $store = app.config.globalProperties.$store

    const scrollToTop = ({ smooth = true } = {}) => {
      const openPopup = $store.state.layout.openPopups[$store.state.layout.openPopups.length - 1]
      const behavior = smooth ? 'smooth' : 'instant'

      if (openPopup) {
        const scrollContainer = openPopup?.component?.$refs?.modal || openPopup?.component?.$refs?.overlay // Scroll the modal/overlay which is on top of the screen

        scrollContainer.scrollIntoView({
          block: 'start',
          behavior,
        })
      } else {
        window.scrollTo({
          top: 0,
          left: 0,
          behavior,
        })
      }
    }

    app.config.globalProperties.$scrollToTop = scrollToTop
  },
}
