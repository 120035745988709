import { getResponseData } from '../utils/get-response-data.js'
import Activity from '../models/activity.model.js'
import Plan from '../models/plan.model.js'

export const getPlans = async ({ axios, projectId, signal }) => {
  const response = await axios.get('plans', {
    signal,
    params: {
      projectid: projectId,
    },
  })

  const data = getResponseData(response)
  if (!data) { return }

  // Freeze Objects to prevent data binding on plans which causes slow performance
  return data.map(p => Object.freeze(new Plan(p)))
}

export const getPlan = async ({ axios, planId, signal }) => {
  const response = await axios.get(`plans/${planId}`, { signal })

  const data = getResponseData(response)
  if (!data) { return }

  return new Plan(data)
}

export const getLatestCreatedPlan = async ({ axios, projectId }) => {
  const response = await axios.get('plans', {
    params: {
      projectid: projectId,
      filter: 'latest-by-user',
    },
  })

  const data = getResponseData(response)
  if (!data) { return }

  if (data.length === 0) {
    return null
  }

  return new Plan(data[0])
}

export const postPlan = async ({ axios, plan }) => {
  // remove computed prop
  const { computed, ...postPlan } = plan

  const { data } = await axios.post('plans', postPlan)

  return new Plan(data)
}

export const importPlans = async ({ axios, sourceProject, targetProject, settings, imports }) => {
  const postBody = {
    sourceProjectId: sourceProject._id,
    targetProjectId: targetProject._id,
    settings,
    imports,
  }

  let { data } = await axios.post('plans/import', postBody)

  data = data.map(dataEntry => {
    if (dataEntry.targetPlan) {
      return {
        ...dataEntry,
        targetPlan: new Plan(dataEntry.targetPlan),
      }
    }

    return dataEntry
  })

  return data
}

export const updateName = async ({ axios, plan, newName, newContent }) => {
  const patchObject = {
    op: 'update',
    path: 'name',
    value: {
      old: {
        name: plan.name,
        content: plan.content,
      },
      new: {
        name: newName,
        content: newContent,
      },
    },
  }

  const { data } = await axios.patch(`plans/${plan._id}`, patchObject)

  return new Plan(data)
}

export const updateAuthor = async ({ axios, plan, newUserId, newRole }) => {
  let oldAuthor = {}
  let newAuthor = {}

  if (plan.author.userId) {
    oldAuthor = {
      userId: plan.author.userId,
    }
  } else if (plan.author.role) {
    oldAuthor = {
      role: plan.author.role,
    }
  }
  if (newUserId) {
    newAuthor = {
      userId: newUserId,
    }
  } else if (newRole) {
    newAuthor = {
      role: newRole,
    }
  }

  const patchObject = {
    op: 'update',
    path: 'author',
    value: {
      old: oldAuthor,
      new: newAuthor,
    },
  }

  const { data } = await axios.patch(`plans/${plan._id}`, patchObject)

  return new Plan(data)
}

export const updateTopCategory = async ({ axios, plan, newTopCategory }) => {
  // if (plan._id === '58b9527aa6b181520b71044a') {
  //   throw new Error('test')
  // }

  const patchObject = {
    op: 'update',
    path: 'topCategory',
    value: {
      old: plan.topCategory,
      new: newTopCategory,
    },
  }

  const { data } = await axios.patch(`plans/${plan._id}`, patchObject)

  return new Plan(data)
}

export const updatePhasetag = async ({ axios, plan, newPhasetag }) => {
  const patchObject = {
    op: 'update',
    path: 'phasetag',
    value: {
      old: plan.phasetag,
      new: newPhasetag,
    },
  }

  const { data } = await axios.patch(`plans/${plan._id}`, patchObject)

  return new Plan(data)
}

export const updateTags = async ({ axios, plan, newValue }) => {
  const patchObject = {
    op: 'update',
    path: 'tags',
    value: {
      old: plan.tags,
      new: newValue,
    },
  }

  const { data } = await axios.patch(`plans/${plan._id}`, patchObject)

  return new Plan(data)
}

export const updateTrashed = async ({ axios, plan, newTrashedValue }) => {
  const patchObject = {
    op: 'update',
    path: 'trashed',
    value: {
      old: plan.trashed,
      new: newTrashedValue,
    },
  }

  const { data } = await axios.patch(`plans/${plan._id}`, patchObject)

  return new Plan(data)
}

export const updateRevisionApproval = async ({ axios, plan, revision, newParticipantIds, newApprovalMode, newApprovalRequestMessage, newApprovalDate }) => {
  const oldApprovalMode = revision.approval.mode.approvalmode
  const oldApprovalRequestMessage = revision.approval.mode.approvalrequestmessage
  const oldApprovalDate = revision.approval.approvalDate

  const oldParticipantIds = revision.approval.by
    .filter(by => by.approved !== 'cancelled')
    .map(approver => approver.participantid)

  const patchObject = {
    op: 'update',
    path: 'approval',
    value: {
      old: {
        byParticipantIds: oldParticipantIds,
        approvalmode: oldApprovalMode,
        approvalrequestmessage: oldApprovalRequestMessage,
        approvalDate: oldApprovalDate,
      },
      new: {
        byParticipantIds: newParticipantIds,
        approvalmode: newApprovalMode,
        approvalrequestmessage: newApprovalRequestMessage,
        approvalDate: newApprovalDate,
      },
    },
  }

  const { data } = await axios.patch(`plans/${plan._id}/revisions/${revision._id}`, patchObject)

  return new Plan(data)
}

export const approveRevisionByMyself = async ({ axios, plan, revision, comment }) => {
  const patchObject = {
    op: 'execute',
    path: 'approveByMyself',
    value: {
      comment,
    },
  }

  const { data } = await axios.patch(`plans/${plan._id}/revisions/${revision._id}`, patchObject)

  return new Plan(data)
}

export const rejectRevisionByMyself = async ({ axios, plan, revision, comment }) => {
  const patchObject = {
    op: 'execute',
    path: 'rejectByMyself',
    value: {
      comment,
    },
  }

  const { data } = await axios.patch(`plans/${plan._id}/revisions/${revision._id}`, patchObject)

  return new Plan(data)
}

export const updateRevisionApprovalStatus = async ({ axios, plan, approver, approvalStatus, message = '', attachments = [] }) => {
  const putObject = {
    approved: approvalStatus,
    comment: {
      message,
      attachments,
    },
  }

  const { data } = await axios.put(`plans/${plan._id}/approvals/${approver._id}`, putObject)

  return new Plan(data)
}

export const addRevisionApprovalComment = async ({ axios, plan, approver, message = '', attachments = [] }) => {
  const postObject = {
    message,
    attachments,
  }

  const { data } = await axios.post(`plans/${plan._id}/approvals/${approver._id}/comments`, postObject)

  return new Plan(data)
}

export const getDownloadRevisionUrl = async ({ axios, planId, revisionId, fileType, contentDisposition = 'attachment' }) => {
  if (!axios || !planId || !revisionId || !fileType) {
    console.error('Arguments for function call "PlansApi.getRevisionDownloadUrl" missing')
  }

  if (!['attachment', 'inline'].includes(contentDisposition)) {
    throw new Error('Invalid contentDisposition')
  }

  const { data } = await axios.get(`plans/${planId}/revisions/${revisionId}/${fileType}?contentDisposition=${contentDisposition}`)

  return data
}

export const logRevisionDownload = async ({ axios, planId, revisionId, fileType }) => {
  const { data } = await axios.post(`plans/${planId}/revisions/${revisionId}/log-download?file-type=${fileType}`)

  return data
}

export const getAttachmentDownloadUrl = async ({ axios, planId, attachmentId, contentDisposition = 'attachment' }) => {
  if (!['attachment', 'inline'].includes(contentDisposition)) {
    throw new Error('Invalid contentDisposition')
  }

  const { data } = await axios.get(`plans/${planId}/attachments/${attachmentId}?contentDisposition=${contentDisposition}`)

  return data
}

export const logAttachmentDownload = async ({ axios, planId, attachmentId }) => {
  const { data } = await axios.post(`plans/${planId}/attachments/${attachmentId}/log-download`)

  return data
}

export const triggerRevisionSend = async ({
  axios,
  planRevisionIdList,
  documentRevisionIdList,
  receiverEmails,
  addedParticipants,
  mailData,
  subject,
  isPdfOnlyActive,
  isShowRecipientsListActive,
}) => {
  const postObject = {
    planRevisionIdList,
    documentRevisionIdList,
    recipients: receiverEmails,
    addedParticipants,
    mailData,
    subject,
    showRecipientsList: isShowRecipientsListActive,
    pdfOnly: isPdfOnlyActive,
  }

  const { data } = await axios.post('plans/mail', postObject)

  return new Activity(data)
}

export const isPlanNameAvailable = async ({ axios, projectId, planId, newPlanName }) => {
  const { data } = await axios.get('plans/isnameavailable', {
    params: {
      newplanname: newPlanName,
      projectid: projectId,
      planid: planId,
    },
  })

  return data
}

export const addRevision = async ({ axios, plan, revision }) => {
  const postObject = revision

  const { data } = await axios.post(`plans/${plan._id}/revisions`, postObject)

  return new Plan(data)
}

export const importRevisions = async ({ axios, sourceProject, targetProject, settings, imports }) => {
  const postBody = {
    sourceProjectId: sourceProject._id,
    targetProjectId: targetProject._id,
    settings,
    imports,
  }

  let { data } = await axios.post('plans/revisions/import', postBody)

  data = data.map(dataEntry => {
    if (dataEntry.targetPlan) {
      return {
        ...dataEntry,
        targetPlan: new Plan(dataEntry.targetPlan),
      }
    }

    return dataEntry
  })

  return data
}

export const getFileAssignmentCandidates = async ({ axios, fileNames, projectId, maxCandidates }) => {
  const { data } = await axios.post('file-assignment-candidates', {
    projectId,
    fileNames,
    maxCandidates,
  })

  return data
}

export const getPlanActivities = async ({ axios, planId }) => {
  const { data } = await axios.get(`plans/${planId}/activities`)

  return data.map(activity => new Activity(activity))
}
