import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  width: "80",
  height: "81",
  viewBox: "0 0 80 81",
  fill: "none",
  xmlns: "http://www.w3.org/2000/svg"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createElementVNode("path", {
      d: "M69.92 65.394V66.574C69.92 67.824 69.42 69.034 68.53 69.914C67.64 70.794 66.44 71.304 65.19 71.304C63.94 71.304 62.73 70.804 61.85 69.914C60.97 69.024 60.46 67.824 60.46 66.574V65.394M77.87 62.984C75.97 60.664 74.63 59.474 74.63 53.074C74.63 47.214 71.64 45.124 69.17 44.104C68.84 43.974 68.53 43.664 68.43 43.324C68 41.854 66.79 40.554 65.17 40.554C63.55 40.554 62.35 41.854 61.92 43.324C61.82 43.664 61.51 43.964 61.18 44.104C58.71 45.124 55.72 47.204 55.72 53.074C55.72 59.474 54.38 60.664 52.48 62.984C51.69 63.944 52.38 65.394 53.76 65.394H76.59C77.96 65.394 78.64 63.944 77.86 62.984H77.87Z",
      stroke: "currentColor",
      "stroke-width": "var(--icon-stroke-width)",
      "stroke-linejoin": "round"
    }, null, -1),
    _createElementVNode("path", {
      d: "M55.48 57.084H13.5C11.98 57.084 10.75 55.854 10.75 54.334V20.664C10.75 19.144 11.98 17.914 13.5 17.914H63.68C65.2 17.914 66.43 19.144 66.43 20.664V40.844",
      stroke: "currentColor",
      "stroke-width": "var(--icon-stroke-width)",
      "stroke-linejoin": "round"
    }, null, -1),
    _createElementVNode("path", {
      d: "M11.59 18.694L37.3 43.614C38.08 44.364 39.31 44.364 40.08 43.614L65.69 18.794",
      stroke: "currentColor",
      "stroke-width": "var(--icon-stroke-width)",
      "stroke-linejoin": "round"
    }, null, -1)
  ])))
}
export default { render: render }