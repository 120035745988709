export default {
  namespaced: true,

  state: () => {
    return {
      searchString: '',
      tags: [],
      tagsAndSwitchActive: true,
      albums: [],
      authorRoles: [],
      authorUserIds: [],
      location1: [],
      location2: [],
      location3: [],
      location4: [],
      locationLayerIds: [],
      hasDescription: undefined,
      capturedAtFrom: undefined,
      capturedAtTo: undefined,

      lastLocalUpdate: null,
      lastServerUpdate: null,
    }
  },

  getters: {
    isFilterActive: (state, getters, rootState) => {
      return state.searchString ||
        state.tags.length > 0 ||
        state.albums.length > 0 ||
        state.authorRoles.length > 0 ||
        state.authorUserIds.length > 0 ||
        state.locationLayerIds.length > 0 ||
        getters.isLocationPropertyFilterActive ||
        state.hasDescription !== undefined ||
        state.capturedAtFrom ||
        state.capturedAtTo
    },

    isLocationPropertyFilterActive: (state, getters, rootState, rootGetters) => {
      const locationPropertiesSortedEnabled = rootGetters['project/locationPropertiesSortedEnabled']

      return locationPropertiesSortedEnabled.some(locationProperty => state[locationProperty].length > 0)
    },
  },

  mutations: {
    SET_FILTERS (state, { filters = {}, modified }) {
      state.searchString = filters.searchString || ''
      state.tags = filters.tags || []
      state.albums = filters.albums || []
      state.authorRoles = filters.authorRoles || []
      state.authorUserIds = filters.authorUserIds || []
      state.tagsAndSwitchActive = filters.tagsAndSwitchActive === undefined ? true : filters.tagsAndSwitchActive
      state.location1 = [...filters.location1]
      state.location2 = [...filters.location2]
      state.location3 = [...filters.location3]
      state.location4 = [...filters.location4]
      state.locationLayerIds = [...filters.locationLayerIds]
      state.hasDescription = filters.hasDescription
      state.capturedAtFrom = filters.capturedAtFrom || undefined
      state.capturedAtTo = filters.capturedAtTo || undefined

      state.lastLocalUpdate = null
      state.lastServerUpdate = new Date(modified)
    },

    RESET_FILTERS (state) {
      state.searchString = ''
      state.tags = []
      state.albums = []
      state.authorUserIds = []
      state.authorRoles = []
      state.tagsAndSwitchActive = true
      state.location1 = []
      state.location2 = []
      state.location3 = []
      state.location4 = []
      state.locationLayerIds = []
      state.hasDescription = undefined
      state.capturedAtFrom = undefined
      state.capturedAtTo = undefined

      state.lastLocalUpdate = new Date()
      state.lastServerUpdate = null
    },

    FULL_RESET (state) {
      state.searchString = ''
      state.tags = []
      state.albums = []
      state.authorRoles = []
      state.authorUserIds = []
      state.tagsAndSwitchActive = true
      state.location1 = []
      state.location2 = []
      state.location3 = []
      state.location4 = []
      state.locationLayerIds = []
      state.hasDescription = undefined
      state.capturedAtFrom = undefined
      state.capturedAtTo = undefined

      state.lastLocalUpdate = null
      state.lastServerUpdate = null
    },
  },
}
