import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  width: "24",
  height: "24",
  viewBox: "0 0 24 24",
  fill: "none",
  xmlns: "http://www.w3.org/2000/svg"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createElementVNode("path", {
      d: "M19.25 7.07546L12.9314 2.55187C12.5963 2.31199 12.4288 2.19204 12.246 2.14565C12.0845 2.10469 11.9154 2.10469 11.754 2.14565C11.5712 2.19204 11.4037 2.31199 11.0686 2.55187L4.74997 7.07548M19.25 7.07546L21.5 8.68627M19.25 7.07546V16.6851C19.25 17.8052 19.25 18.3653 19.032 18.7931C18.8402 19.1694 18.5343 19.4754 18.158 19.6671C17.7301 19.8851 17.1701 19.8851 16.05 19.8851H7.94997C6.82987 19.8851 6.26982 19.8851 5.84199 19.6671C5.46567 19.4754 5.15971 19.1694 4.96796 18.7931C4.74997 18.3653 4.74997 17.8052 4.74997 16.6851V7.07548M4.74997 7.07548L2.5 8.68627",
      stroke: "#FF0000",
      "stroke-width": "1.5",
      "stroke-linecap": "round",
      "stroke-linejoin": "round"
    }, null, -1)
  ])))
}
export default { render: render }