<template>
  <div
    v-if="$auth.loggedIn"
    data-testid-app="body"
    data-testid="default-layout"
    class="uti_position-relative"
    @dragover.prevent
    @dragleave.prevent
    @drop.stop.prevent
  >
    <HeaderBar
      v-if="!$config.isBackupApp"
      data-testid="header-bar-default"
    />

    <HeaderBarBackupApp
      v-if="$config.isBackupApp"
      data-testid="header-bar-backup-app"
    />

    <div
      class="page page--header-padding"
      :class="{
        'page--project-dashboard': $route.name === 'projects-projectId',
        'page--project-settings': $route.name.startsWith('projects-projectId-settings') || $route.name.startsWith('projects-projectId-notifications'),
        'page--project-mailer': $route.name.startsWith('projects-projectId-mailer'),
        'page--projects-list': $route.name === 'projects',
        'page--account': $route.name === 'account',
      }"
      data-testid="page"
    >
      <BannerTrialPeriod />

      <BannerDemoserver
        v-if="$config.isDemoserver"
        data-testid="demoserver-banner"
      />

      <main class="page__content">
        <slot />
      </main>

      <footer>
        <PfFooter />
      </footer>
    </div>

    <transition name="editor-darkener-fade">
      <PfDarkener
        v-if="$store.state.unsavedChanges.showOverlay"
      />
    </transition>

    <ModalAgbDsgvo
      v-if="!userHasConfirmedAgb && !userHasConfirmedDsgvo && userHasProjects"
    />

    <ModalDemoserver
      v-if="$config.isDemoserver && !didAlreadySeeModalDemoserver && !didCloseModalDemoserver"
      @after:close-animation="closeDemoServerModal"
    />

    <ModalTwoFactorAuthDisabled
      v-if="$auth.user?.twoFactorAuth?.disabledViaRecoveryCode"
    />

    <GoToTop />

    <DevDebugBar />
  </div>
</template>

<script>
import AwsS3 from '@uppy/aws-s3'

import BannerDemoserver from '@/components/BannerDemoserver.vue'
import BannerTrialPeriod from '@/components/BannerTrialPeriod.vue'
import DevDebugBar from '@/components/DevDebugBar.vue'
import GoToTop from '@/components/GoToTop.vue'
import HeaderBar from '@/components/HeaderBar.vue'
import HeaderBarBackupApp from '@/components/HeaderBarBackupApp.vue'
import ModalAgbDsgvo from '@/components/ModalAgbDsgvo.vue'
import ModalDemoserver from '@/components/ModalDemoserver.vue'
import ModalTwoFactorAuthDisabled from '@/components/ModalTwoFactorAuthDisabled.vue'
import PfDarkener from '@/components/PfDarkener.vue'
import PfFooter from '@/components/PfFooter.vue'
import asyncDataErrorHandler from '@/utils/async-data-error-handler'

export default {
  components: {
    HeaderBar,
    HeaderBarBackupApp,
    PfFooter,
    PfDarkener,
    GoToTop,
    BannerTrialPeriod,
    BannerDemoserver,
    ModalAgbDsgvo,
    ModalDemoserver,
    ModalTwoFactorAuthDisabled,
    DevDebugBar,
  },

  data () {
    return {
      didCloseModalDemoserver: false,
      unloadListener: null,
    }
  },

  head () {
    return {
      htmlAttrs: {
        class: this.$store.state.layout.htmlClassNames,
      },

      title: this.$config.headTitleTag,
      meta: [
        { name: 'application-name', content: this.$config.headTitleTag },
        { name: 'apple-mobile-web-app-title', content: this.$config.headTitleTag },
      ],
    }
  },

  computed: {
    userHasConfirmedAgb () { return this.$auth.user.crm.confirmedAGB },
    userHasConfirmedDsgvo () { return this.$auth.user.crm.confirmedDSGVO },
    userHasProjects () { return this.$auth.user.crm && this.$auth.user.crm.numberOfProjects > 0 },

    didAlreadySeeModalDemoserver () {
      return window.localStorage.getItem(this.$config.constants.LOCAL_STORAGE_DEMOSERVER_MODAL) === 'true'
    },
  },

  watch: {
    $route: {
      handler (newRoute, oldRoute) {
      // We fetch the current-user on route change within a watcher function and not in a middleware, because doing it within a watcher causes the API request to happen really async (after rendering). Doing fetchUser in middleware causes the route change to block until the current-user got loaded
        if (oldRoute) {
          this.$auth.fetchUser()
        }

        const isLoggedIn = this.$auth.loggedIn

        const transitionToProjectPage = (!oldRoute || !oldRoute.name.startsWith('projects-projectId')) && newRoute.name.startsWith('projects-projectId')
        const transitionToNonProjectPage = (!oldRoute || oldRoute.name.startsWith('projects-projectId')) && !newRoute.name.startsWith('projects-projectId')
        const transitionToDifferentProjectPage = oldRoute && oldRoute.name.startsWith('projects-projectId') && newRoute.name.startsWith('projects-projectId') && oldRoute.params.projectId !== newRoute.params.projectId

        if (isLoggedIn && transitionToDifferentProjectPage) {
          this.$socket.disconnect()
          this.$socket.connect(newRoute.params.projectId)
          return
        }

        if (!isLoggedIn || transitionToNonProjectPage) {
          this.$socket.disconnect()
        }

        if (isLoggedIn && transitionToProjectPage) {
          this.$socket.connect(newRoute.params.projectId)
        }
      },

      immediate: true,
    },
  },

  unmounted () {
    this.$socket.disconnect()
    window.removeEventListener('beforeunload', this.unloadListener)
  },

  async created () {
    // This event listener is needed because of the following behaviour:
    // File downloads in planfred are triggered via window.location.href
    // However calling location.href tears down the current page JS environment which also kills the websocket. (https://stackoverflow.com/questions/24009218/on-click-a-tag-with-href-socket-gets-disconnected)
    // As soon as the JS environment got torn down, the beforeunload event gets triggered. This is the place where we introduce a new socket connection
    this.unloadListener = window.addEventListener('beforeunload', () => {
      window.setTimeout(() => { // setTimeout 0 => Add it to the end of the message queue to prevent bugs (most likely in Firefox)
        this.$socket.disconnect()

        if (this.$route.params.projectId) {
          this.$socket.connect(this.$route.params.projectId)
        }
      }, 0)
    })

    this.$store.commit('layout/ADD_HTML_CLASS', this.$config.customerFeatures.htmlTagClass)

    // Only display editor when edit plan in new tab selected
    if (this.$route.query.editintab) {
      this.$store.commit('layout/ADD_HTML_CLASS', this.$config.constants.HTML_TAG_CLASS_EDIT_IN_TAB)
    }

    try {
      // Fetch projects for counter in header. Is loaded in the created hook, because we don't depend on this counter. This request can resolve at any time
      if (!this.$store.state.projects.list.length) {
        await this.$store.dispatch('projects/fetch')
      }

      if (!this.$clipboardUppy.getPlugin('AwsS3') && !this.$clipboardUppy.getPlugin('AwsS3Multipart')) {
        // Only add the plugin if it wasn't added before (like if you logout and login again)
        this.$clipboardUppy
          .use(AwsS3, {
            shouldUseMultipart: true,
            headers: {
              Authorization: `Bearer ${this.$auth.user?.uploadToken}`,
              // 'uppy-auth-token': `Bearer ${this.$auth.user?.uploadToken}`,
            },
            limit: 5,
            endpoint: this.$config.api.companionUrl,
          })
      }
    } catch (e) {
      if (e.response) {
        return asyncDataErrorHandler({ e, routeName: this.$route.name })
      }

      throw e
    }
  },

  mounted () {
    window.addEventListener('beforeunload', this.onBeforeUnload)

    // set scrollbar width as css var
    window.document.documentElement.style.setProperty('--pf-scrollbar-width', window.innerWidth - document.documentElement.clientWidth + 'px')
  },

  beforeUnmount () {
    window.removeEventListener('beforeunload', this.onBeforeUnload)
  },

  methods: {
    onBeforeUnload () {
      if (window.Cypress) {
        return // We have to prevent the unload behaviour for the tests, because otherwise the clipboard isOpen state would be passed from one test to another, because of the clipboard/RESET_ASSIGNED_TMP commit, which triggers a localStorage write
      }

      const currentUploads = this.$clipboardUppy.getState().currentUploads || {}
      const hasRunningUploads = Object.keys(currentUploads).length

      this.$store.commit('clipboard/RESET_ASSIGNED_TMP')

      if (hasRunningUploads) {
        // Show dialog if user closes window with running uploads. Some browsers use the text returned from this function, but most of the browsers will show a default text
        event.returnValue = this.$t('feature.project_header.clipboard.running_uploads_leave_anyways')
        return this.$t('feature.project_header.clipboard.running_uploads_leave_anyways')
      }
    },

    closeDemoServerModal () {
      this.didCloseModalDemoserver = true
    },
  },
}
</script>
