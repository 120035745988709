import Activity from '../models/activity.model.js'
import Task from '../models/task.model.js'
import { getResponseData } from '../utils/get-response-data.js'

export const getTasks = async ({
  axios,
  projectId,
  loadAll,
  signal,
}) => {
  const response = await axios.get('tasks', {
    signal,
    params: {
      projectid: projectId,
      loadAll: loadAll || undefined,
    },
  })

  const data = getResponseData(response)
  if (!data) { return }

  // Freeze Objects to prevent data binding on tasks which causes slow performance
  const tasks = data.list.map(p => Object.freeze(new Task(p)))

  return {
    tasks,
    missingPublishedTasks: data.missingPublishedTasks,
    missingTrashedTasks: data.missingTrashedTasks,
  }
}

export const getTask = async ({
  axios,
  taskId,
  signal,
}) => {
  const response = await axios.get(`tasks/${taskId}`, { signal })

  const data = getResponseData(response)
  if (!data) { return }

  return new Task(data)
}

export const postTask = async ({
  axios,
  task,
}) => {
  const { data } = await axios.post('tasks/', task)

  return new Task(data)
}

export const getLatestCreatedTaskByUser = async ({
  axios,
  projectId,
}) => {
  const { data } = await axios.get('tasks/latest-by-user', {
    params: {
      projectid: projectId,
    },
  })

  return data && Object.keys(data).length > 0 ? new Task(data) : undefined // keys check needed for backup app
}

export const updateTaskType = async ({
  axios,
  task,
  type,
}) => {
  const patchObject = {
    op: 'update',
    path: 'type',
    value: {
      old: task.type,
      new: type,
    },
  }

  const { data } = await axios.patch(`tasks/${task._id}`, patchObject)

  return new Task(data)
}

export const updateTaskAssigneeUserId = async ({
  axios,
  task,
  userId,
}) => {
  const patchObject = {
    op: 'update',
    path: 'assigneeUserId',
    value: {
      old: task.assigneeUserId,
      new: userId,
    },
  }

  const { data } = await axios.patch(`tasks/${task._id}`, patchObject)

  return new Task(data)
}

export const updateTaskDueDate = async ({
  axios,
  task,
  dueDate,
}) => {
  const patchObject = {
    op: 'update',
    path: 'dueDate',
    value: {
      old: task.dueDate,
      new: dueDate,
    },
  }

  const { data } = await axios.patch(`tasks/${task._id}`, patchObject)

  return new Task(data)
}

export const updateTaskLocations = async ({
  axios,
  task,
  location1,
  location2,
  location3,
  location4,
  locationLayerId,
  locationPins,
}) => {
  const patchObject = {
    op: 'update',
    path: 'locations',
    value: {
      old: {
        location1: task.location1,
        location2: task.location2,
        location3: task.location3,
        location4: task.location4,
        locationLayerId: task.locationLayerId,
        locationPins: task.locationPins,
      },
      new: {
        location1,
        location2,
        location3,
        location4,
        locationLayerId,
        locationPins,
      },
    },
  }

  const { data } = await axios.patch(`tasks/${task._id}`, patchObject)

  return new Task(data)
}

export const updateTaskLocation = async ({
  axios,
  task,
  locationProperty,
  location,
}) => {
  const patchObject = {
    op: 'update',
    path: locationProperty,
    value: {
      old: task[locationProperty],
      new: location,
    },
  }

  const { data } = await axios.patch(`tasks/${task._id}`, patchObject)

  return new Task(data)
}

export const updateTaskTags = async ({
  axios,
  task,
  tags,
}) => {
  const patchObject = {
    op: 'update',
    path: 'tags',
    value: {
      old: task.tags,
      new: tags,
    },
  }

  const { data } = await axios.patch(`tasks/${task._id}`, patchObject)

  return new Task(data)
}

export const publishTask = async ({
  axios,
  task,
}) => {
  const patchObject = {
    op: 'execute',
    path: 'publish',
  }

  const { data } = await axios.patch(`tasks/${task._id}`, patchObject)

  return new Task(data)
}

export const updateTaskTitle = async ({
  axios,
  task,
  title,
}) => {
  const patchObject = {
    op: 'update',
    path: 'title',
    value: {
      old: task.title,
      new: title,
    },
  }

  const { data } = await axios.patch(`tasks/${task._id}`, patchObject)

  return new Task(data)
}

export const updateTaskDescription = async ({
  axios,
  task,
  description,
}) => {
  const patchObject = {
    op: 'update',
    path: 'description',
    value: {
      old: task.description,
      new: description,
    },
  }

  const { data } = await axios.patch(`tasks/${task._id}`, patchObject)

  return new Task(data)
}

export const addTaskFiles = async ({
  axios,
  taskId,
  files,
}) => {
  const patchObject = {
    op: 'add',
    path: 'files',
    value: files,
  }

  const { data } = await axios.patch(`tasks/${taskId}`, patchObject)

  return new Task(data)
}

export const updateTaskFiles = async ({
  axios,
  taskId,
  oldFiles,
  newFiles,
}) => {
  const patchObject = {
    op: 'update',
    path: 'files',
    value: {
      old: oldFiles,
      new: newFiles,
    },
  }

  const { data } = await axios.patch(`tasks/${taskId}`, patchObject)

  return new Task(data)
}

export const replaceTaskFile = async ({
  axios,
  taskId,
  fileId,
  newFile,
}) => {
  const patchObject = {
    op: 'update',
    path: 'file',
    value: {
      fileId,
      newFile,
    },
  }

  const { data } = await axios.patch(`tasks/${taskId}`, patchObject)

  return new Task(data)
}

export const replaceTaskCommentFile = async ({
  axios,
  taskId,
  taskCommentId,
  fileId,
  newFile,
}) => {
  const patchObject = {
    op: 'update',
    path: 'file',
    value: {
      fileId,
      newFile,
    },
  }

  const { data } = await axios.patch(`tasks/${taskId}/comments/${taskCommentId}`, patchObject)

  return new Task(data)
}

export const deleteTaskFiles = async ({
  axios,
  taskId,
  fileIds,
}) => {
  const patchObject = {
    op: 'delete',
    path: 'files',
    value: fileIds,
  }

  const { data } = await axios.patch(`tasks/${taskId}`, patchObject)

  return new Task(data)
}

export const reorderTaskFiles = async ({
  axios,
  task,
  fileIds,
}) => {
  const patchObject = {
    op: 'reorder',
    path: 'files',
    value: {
      old: task.files.map(file => file._id),
      new: fileIds,
    },
  }

  const { data } = await axios.patch(`tasks/${task._id}`, patchObject)

  return new Task(data)
}

export const reorderTaskCommentFiles = async ({
  axios,
  task,
  taskComment,
  fileIds,
}) => {
  const patchObject = {
    op: 'reorder',
    path: 'files',
    value: {
      old: taskComment.files.map(file => file._id),
      new: fileIds,
    },
  }

  const { data } = await axios.patch(`tasks/${task._id}/comments/${taskComment._id}`, patchObject)

  return new Task(data)
}

export const postTaskComment = async ({
  axios,
  taskId,
  taskComment,
}) => {
  const { data } = await axios.post(`tasks/${taskId}/comments`, taskComment)

  return new Task(data)
}

export const updateTaskCommentFiles = async ({
  axios,
  taskId,
  taskCommentId,
  oldFiles,
  newFiles,
}) => {
  const patchObject = {
    op: 'update',
    path: 'files',
    value: {
      old: oldFiles,
      new: newFiles,
    },
  }

  const { data } = await axios.patch(`tasks/${taskId}/comments/${taskCommentId}`, patchObject)

  return new Task(data)
}

export const addTaskCommentFiles = async ({
  axios,
  taskId,
  taskCommentId,
  files,
}) => {
  const patchObject = {
    op: 'add',
    path: 'files',
    value: files,
  }

  const { data } = await axios.patch(`tasks/${taskId}/comments/${taskCommentId}`, patchObject)

  return new Task(data)
}

export const deleteTaskCommentFiles = async ({
  axios,
  taskId,
  taskCommentId,
  fileIds,
}) => {
  const patchObject = {
    op: 'delete',
    path: 'files',
    value: fileIds,
  }

  const { data } = await axios.patch(`tasks/${taskId}/comments/${taskCommentId}`, patchObject)

  return new Task(data)
}

export const updateTaskCommentMessage = async ({
  axios,
  task,
  taskComment,
  message,
}) => {
  const patchObject = {
    op: 'update',
    path: 'message',
    value: {
      old: taskComment.message,
      new: message,
    },
  }

  const { data } = await axios.patch(`tasks/${task._id}/comments/${taskComment._id}`, patchObject)

  return new Task(data)
}

export const updateTrashed = async ({
  axios,
  task,
  isTrashed,
}) => {
  const patchObject = {
    op: 'update',
    path: 'trashed',
    value: {
      old: task.trashed,
      new: isTrashed,
    },
  }

  const { data } = await axios.patch(`tasks/${task._id}`, patchObject)

  return new Task(data)
}

export const deleteTaskHard = async ({
  axios,
  task,
}) => {
  await axios.delete(`tasks/${task._id}`)
}

export const getSignedTaskFileDownloadUrl = async ({
  axios,
  taskId,
  fileId,
  contentDisposition = 'attachment',
  resolution = '',
}) => {
  if (!['attachment', 'inline'].includes(contentDisposition)) {
    throw new Error('Invalid contentDisposition')
  }

  const { data } = await axios.get(`tasks/${taskId}/files/${fileId}?contentDisposition=${contentDisposition}&resolution=${resolution}`)

  return data.url
}

export const getSignedTaskLocationMapImageDownloadUrl = async ({
  axios,
  taskId,
  contentDisposition = 'inline',
  resolution = '',
}) => {
  if (!['attachment', 'inline'].includes(contentDisposition)) {
    throw new Error('Invalid contentDisposition')
  }

  const { data } = await axios.get(`tasks/${taskId}/location-map-image?contentDisposition=${contentDisposition}&resolution=${resolution}`)

  return data.url
}

export const getSignedTaskCommentFileDownloadUrl = async ({
  axios,
  taskId,
  taskCommentId,
  fileId,
  contentDisposition = 'attachment',
  resolution = '',
}) => {
  if (!['attachment', 'inline'].includes(contentDisposition)) {
    throw new Error('Invalid contentDisposition')
  }

  const { data } = await axios.get(`tasks/${taskId}/comments/${taskCommentId}/files/${fileId}?contentDisposition=${contentDisposition}&resolution=${resolution}`)

  return data.url
}

export const getActivitiesFromTask = async ({
  axios,
  taskId,
}) => {
  const { data } = await axios.get(`tasks/${taskId}/activities`)

  return data.map(p => new Activity(p))
}

export const getActivitiesFromTaskComment = async ({
  axios,
  taskId,
  commentId,
}) => {
  const { data } = await axios.get(`tasks/${taskId}/comments/${commentId}/activities`)

  return data.map(p => new Activity(p))
}

export const triggerTaskReminderSend = async ({
  axios,
  taskIds,
  receiverEmails,
  mailData,
  subject,
}) => {
  const postBody = {
    taskIds,
    receiverEmails,
    mailData,
    subject,
  }
  const { data } = await axios.post('tasks/mail/', postBody)

  return new Activity(data)
}
