import LocationPin from './location-pin.model'

export default class Photo {
  constructor ({ _id, projectId, title, author, description, trashed, consecutiveNumber, creatorUserId, creatorRole, tags, album, pfFileId, location1, location2, location3, location4, locationLayerId, latestPinNumber, locationPins, created, modified, capturedAt } = { }) {
    this._id = _id
    this.projectId = projectId
    this.title = title
    this.author = author
    this.creatorUserId = creatorUserId
    this.creatorRole = creatorRole
    this.description = description
    this.trashed = trashed
    this.consecutiveNumber = consecutiveNumber
    this.tags = tags
    this.album = album
    this.pfFileId = pfFileId
    this.location1 = location1
    this.location2 = location2
    this.location3 = location3
    this.location4 = location4
    this.locationLayerId = locationLayerId
    this.latestPinNumber = latestPinNumber
    this.locationPins = locationPins ? locationPins.map(pin => new LocationPin(pin)) : []
    this.created = created
    this.modified = modified
    this.capturedAt = capturedAt
  }
}
