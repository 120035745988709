import photosFilter from '@/store/photosFilter'

export default {
  namespaced: true,

  state: () => {
    return {
      ...photosFilter.state(),
    }
  },

  getters: {
    ...photosFilter.getters,
  },

  mutations: {
    ...photosFilter.mutations,
  },
}
