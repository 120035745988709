import constants from '@/config/constants'

const localStorageSync = (store) => {
  const getLocalStorageKeyUserProject = () => {
    if (!store.state.project.project) {
      return
    }

    const projectId = store.state.project.project._id
    const userId = store.state.currentUser.user ? store.state.currentUser.user._id : null

    if (!projectId || !userId) {
      return
    }

    return [
      constants.LOCAL_STORAGE_PREFIX,
      userId,
      projectId,
    ].join('-')
  }

  const getLocalStorageKeyUser = () => {
    if (!store.state.currentUser.user) {
      return
    }

    const userId = store.state.currentUser.user._id

    if (!userId) {
      return
    }

    return [
      constants.LOCAL_STORAGE_PREFIX,
      userId,
    ].join('-')
  }

  const getLocalStorageKeyDownloadFormat = () => {
    const userId = store.state.currentUser.user && store.state.currentUser.user._id

    // when not logged in, use global setting
    if (!userId) {
      return [constants.LOCAL_STORAGE_PREFIX, 'downloadpage'].join('-')
    }

    // when logged in, use user specific setting across projects
    return [
      constants.LOCAL_STORAGE_PREFIX,
      userId,
    ].join('-')
  }

  // storeNames which should be stored in the localstorage
  // Can be either a string, or an object, defining which exact properties should get saved
  const paths = [
    {
      storeName: 'plans',
      properties: ['sortSettings', 'printSettings'],
      getKey: getLocalStorageKeyUserProject,
    },
    {
      storeName: 'plansNewPrefill',
      properties: ['isEnabled', 'features'],
      getKey: getLocalStorageKeyUserProject,
    },
    {
      storeName: 'plansListModus',
      getKey: getLocalStorageKeyUserProject,
    },
    {
      storeName: 'documents',
      properties: ['sortSettings', 'printSettings'],
      getKey: getLocalStorageKeyUserProject,
    },
    {
      storeName: 'documentsNewPrefill',
      properties: ['isEnabled', 'features'],
      getKey: getLocalStorageKeyUserProject,
    },
    {
      storeName: 'documentsListModus',
      getKey: getLocalStorageKeyUserProject,
    },
    {
      storeName: 'tasks',
      properties: ['subTab', 'sortSettings', 'printSettings', 'listSettings', 'listStyle'],
      getKey: getLocalStorageKeyUserProject,
    },
    {
      storeName: 'tasksNewPrefill',
      properties: ['isEnabled', 'features'],
      getKey: getLocalStorageKeyUserProject,
    },
    {
      storeName: 'tasksListModus',
      getKey: getLocalStorageKeyUserProject,
    },
    {
      storeName: 'photos',
      properties: ['listStyle', 'showMetadata', 'groupBy', 'sortProperty'],
      getKey: getLocalStorageKeyUserProject,
    },
    {
      storeName: 'photosNewPrefill',
      properties: ['isEnabled', 'features'],
      getKey: getLocalStorageKeyUserProject,
    },
    {
      storeName: 'photosListModus',
      getKey: getLocalStorageKeyUserProject,
    },
    {
      storeName: 'participants',
      properties: ['sortSettings', 'printSettings'],
      getKey: getLocalStorageKeyUserProject,
    },
    {
      storeName: 'participantsListModus',
      getKey: getLocalStorageKeyUserProject,
    },
    {
      storeName: 'projectsFilter',
      getKey: getLocalStorageKeyUser,
    },
    {
      storeName: 'clipboard',
      properties: ['files', 'sortSettings', 'filter'],
      getKey: getLocalStorageKeyUserProject,
    },
    {
      storeName: 'download',
      properties: ['downloadFormat'],
      getKey: getLocalStorageKeyDownloadFormat,
    },
    {
      storeName: 'recipientGroups',
      getKey: getLocalStorageKeyUserProject,
    },
    {
      storeName: 'projects',
      properties: ['sortProperty'],
      getKey: getLocalStorageKeyUser,
    },
    {
      storeName: 'pfFiles',
      properties: ['list', 'lastFetchedAt'],
      getKey: getLocalStorageKeyUserProject,
    },
  ]

  // Runs for EVERY mutation
  store.subscribe((mutation, state) => {
    const mutationStoreName = mutation.type.split('/')[0]

    const pathObject = paths.find((pathObject) => mutationStoreName === pathObject.storeName)
    if (!pathObject) {
      return
    }

    let subStore = {
      [mutationStoreName]: {},
    }

    const localStorageKey = pathObject.getKey()
    if (!localStorageKey) {
      return
    }

    const localStorageContent = JSON.parse(localStorage.getItem(localStorageKey) || '{}')

    if (Array.isArray(pathObject.properties)) {
      // Being in this if means, we defined an Object in the paths array above and we only want to store a subset of the store. So we only want to save a subset of the store in the localstorage
      pathObject.properties.forEach((property) => {
        subStore[mutationStoreName][property] = state[mutationStoreName][property]
      })
    } else {
      subStore = {
        [mutationStoreName]: state[mutationStoreName],
      }
    }

    localStorage.setItem(
      localStorageKey,
      JSON.stringify({
        ...localStorageContent,
        ...subStore,
      }),
    )
  })
}

export default localStorageSync
