import { getLatestCreatedTaskByUser } from '@client-shared/api/tasks.api'

export default {
  namespaced: true,

  state: () => {
    return {
      isEnabled: false,
      isOpen: false,
      latestCreatedTaskByUser: undefined,

      features: {
        useTitle: true,
        useDescription: true,
        // useFiles: true, // TODO: FILES_PREFILL_FEATURE (deactivated)
        useAssignee: true,
        useTags: true,
        useDueDate: true,
        useType: true,
        useLocation: true,
        useLocationPins: true,
      },
    }
  },

  mutations: {
    SET_LATEST_CREATED_TASK_BY_USER (state, task) {
      state.latestCreatedTaskByUser = task
    },

    SET_IS_ENABLED (state, isEnabled) {
      state.isEnabled = isEnabled
    },

    TOGGLE_IS_ENABLED (state) {
      state.isEnabled = !state.isEnabled
    },

    TOGGLE_IS_OPEN (state) {
      state.isOpen = !state.isOpen
    },

    SET_FEATURE_TITLE (state, value) {
      state.features.useTitle = value
    },

    SET_FEATURE_DESCRIPTION (state, value) {
      state.features.useDescription = value
    },

    // TODO: FILES_PREFILL_FEATURE (deactivated)
    // SET_FEATURE_FILES (state, value) {
    //   state.features.useFiles = value
    // },

    SET_FEATURE_ASSIGNEE (state, value) {
      state.features.useAssignee = value
    },

    SET_FEATURE_TAGS (state, value) {
      state.features.useTags = value
    },

    SET_FEATURE_DUE_DATE (state, value) {
      state.features.useDueDate = value
    },

    SET_FEATURE_TYPE (state, value) {
      state.features.useType = value
    },

    SET_FEATURE_LOCATION (state, value) {
      state.features.useLocation = value
    },

    SET_FEATURE_LOCATION_PINS (state, value) {
      state.features.useLocationPins = value
    },

    FULL_RESET (state) {
      state.latestCreatedTaskByUser = undefined
    },
  },

  actions: {
    async fetchLatestCreatedTaskByUser ({ commit }, { projectId }) {
      const task = await getLatestCreatedTaskByUser({
        axios: this.$axios,
        projectId,
      })

      commit('SET_LATEST_CREATED_TASK_BY_USER', task)
    },

  },
}
