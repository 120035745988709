const template = {
  roles: [
    'Architect',
    'Building physics',
    'Fire protection',
    'Electrical-planning',
    'Building services-planning',
    'Construction supervisor',
    'Structural engineer',
    'Building owner',
    'Project management',
  ],
  phasetags: [
    'Bases',
    'Preliminary design',
    'Final design',
    'Building permission plan',
    'Execution plan',
    'Detailed plan',
    'Formwork plan',
    'Reinforcement plan',
    'Installation drawing',
    'Contracted award',
    'Practical completion',
    'As-built plan',
  ],

  phasetagsDocuments: [
    'Common',
    'Authorities',
    'Product sheets',
    'Building permission doc.',
    'Pictures',
    'Bases',
    'Expert reports',
    'Lists',
    'Protocols',
    'Schedules',
  ],
  tags: {
    plans: [
      'Ground plan',
      'Section plan',
      'View plan',
      'Site plan',
    ],
    documents: [
      'Construction meeting',
      'Planning meeting',
      'Door list',
      'Plan list',
    ],
    tasks: [],
    photos: [],
  },
  topCategoriesPlansEnabled: false,
  topCategoriesPlans: ['Standard'],
  topCategoriesDocumentsEnabled: false,
  topCategoriesDocuments: ['Standard'],
  photoAlbums: ['General'],

  taskTypes: [
    'To do',
    'Defect',
    'Clarification',
  ],

  locations: {
    location1: {
      isEnabled: false,
      label: 'Building',
      order: 0,
      values: [
        'Object 1',
        'Object 2',
        'Object 3',
        'Outbuilding',
        'Garage',
      ],
    },

    location2: {
      isEnabled: true,
      label: 'Floor',
      order: 1,
      values: [
        '2. basement',
        '1. basement',
        'ground floor',
        '1. floor',
        '2. floor',
        '3. floor',
        '4. floor',
        '5. floor',
        '1. attic',
        '2. attic',
        'roof top view',
      ],
    },

    location3: {
      isEnabled: true,
      label: 'Unit',
      order: 2,
      values: [
        'Outdoor facilities',
        'Office 1',
        'Office 2',
        'Storage 1',
        'Storage 2',
        'Shop 1',
        'Shop 2',
        'Staircase 1',
        'Staircase 2',
        'Appartment 1',
        'Appartment 2',
        'Appartment 3',
        'Appartment 4',
        'Appartment 5',
        'Appartment 6',
        'Appartment 7',
        'Appartment 8',
        'Appartment 9',
        'Appartment 10',
        'Appartment 11',
        'Appartment 12',
        'Appartment 13',
        'Appartment 14',
        'Appartment 15',
        'Appartment 16',
        'Appartment 17',
        'Appartment 18',
        'Appartment 19',
        'Appartment 20',
      ],
    },

    location4: {
      isEnabled: true,
      label: 'Space',
      order: 3,
      values: [
        'Store r.',
        'Bath',
        'Bath / wc',
        'Balcony',
        'Office',
        'Dining',
        'Bike room',
        'Garden',
        'Techn. r.',
        'Stroller r.',
        'Kitchen',
        'Garbage r.',
        'Cabinet',
        'Terrace',
        'Anteroom',
        'Wc',
        'Living room',
        'Room 1',
        'Room 2',
        'Room 3',
      ],
    },
  },
}

export default template
