<template>
  <div
    data-testid-app="body"
    data-testid="public-layout"
  >
    <div
      class="page page--public"
      data-testid="page"
    >
      <DecorativeWaves />

      <main
        class="page__content public-layout"
      >
        <div class="web_public-card">
          <div
            class="web_public-card__top-link"
          >
            <a
              v-if="$config.showGoToWebsiteLink"
              data-testid="go-to-website-link"
              :href="$t(`layout.go_to_website_url`, { hostname })"
              class="obj_pill-button obj_pill-button--nobg"
            >
              <span
                class="label"
              >
                {{ $t(`layout.go_to_website`) }}
              </span>

              <PfIcon
                name="arrowRight"
                class="icon"
              />
            </a>
          </div>

          <div
            class="web_public-card__logo"
            data-testid="layout-public-logo"
          />

          <div
            class="web_public-card__content"
          >
            <slot />

            <div
              class="web_public-card__app-promotion com_promo-banner"
            >
              <div
                class="com_promo-banner__text"
                v-html="$t('feature.auth.app_promotion_text')"
              />

              <div
                class="com_promo-banner__action"
              >
                <a
                  href="https://apps.apple.com/at/app/planfred/id1581604572?"
                  target="_blank"
                >
                  <img
                    class="com_promo-banner__action__image"
                    alt="Apple App Store Link"
                    :src="appStoreImage"
                  >
                </a>

                <a
                  href="https://play.google.com/store/apps/details?id=com.planfred.app"
                  target="_blank"
                >
                  <img
                    class="com_promo-banner__action__image"
                    alt="Google Play Store Link"
                    :src="playStoreImage"
                  >
                </a>
              </div>
            </div>
          </div>
        </div>

        <SupportInfo />
      </main>

      <footer>
        <PfFooter />
      </footer>
    </div>

    <DevDebugBar />
  </div>
</template>

<script>
import isOnLocalhost from '@client-shared/utils/is-on-localhost'

import appStoreImage from '@/assets/img/badge_apple-app-store.svg?url'
import playStoreImage from '@/assets/img/badge_google-play-store.svg?url'
import DecorativeWaves from '@/components/DecorativeWaves.vue'
import DevDebugBar from '@/components/DevDebugBar.vue'
import PfFooter from '@/components/PfFooter.vue'
import SupportInfo from '@/components/SupportInfo.vue'

export default {
  components: {
    DecorativeWaves,
    PfFooter,
    SupportInfo,
    DevDebugBar,
  },

  data () {
    return {
      appStoreImage,
      playStoreImage,
    }
  },

  head () {
    return {
      htmlAttrs: {
        class: this.$store.state.layout.htmlClassNames,
      },

      title: this.$config.headTitleTag,
      meta: [
        { name: 'application-name', content: this.$config.headTitleTag },
        { name: 'apple-mobile-web-app-title', content: this.$config.headTitleTag },
      ],
    }
  },

  computed: {
    hostname: () => {
      if (window.location.hostname === 'app.planfred.com.planfredapp.com') {
        return 'www.planfred.com.planfredapp.com'
      }

      if (isOnLocalhost) {
        return 'localhost:8888'
      }

      return 'www.planfred.com'
    },
  },

  created () {
    this.$store.commit('layout/ADD_HTML_CLASS', this.$config.customerFeatures.htmlTagClass)
  },
}
</script>
