import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createStaticVNode as _createStaticVNode } from "vue"

const _hoisted_1 = {
  width: "80",
  height: "80",
  viewBox: "0 0 80 80",
  fill: "none",
  xmlns: "http://www.w3.org/2000/svg"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createStaticVNode("<path d=\"M26.168 12.728H17.16C15.968 12.728 15 13.696 15 14.896V72.16C15 73.36 15.968 74.328 17.16 74.328H62.44C63.632 74.328 64.6 73.36 64.6 72.16V14.904C64.6 13.704 63.632 12.736 62.44 12.736H53.416\" stroke=\"currentColor\" stroke-width=\"var(--icon-stroke-width)\"></path><path d=\"M39.8 12.8C42.2301 12.8 44.2 10.8301 44.2 8.4C44.2 5.96995 42.2301 4 39.8 4C37.3699 4 35.4 5.96995 35.4 8.4C35.4 10.8301 37.3699 12.8 39.8 12.8Z\" stroke=\"currentColor\" stroke-width=\"var(--icon-stroke-width)\"></path><path d=\"M36.096 8.40002H27.856C26.976 8.40002 26.264 9.11202 26.264 9.99202V14.808C26.264 16.864 27.928 18.52 29.976 18.52H49.616C51.672 18.52 53.328 16.856 53.328 14.808V9.99202C53.328 9.11202 52.616 8.40002 51.736 8.40002H43.608\" stroke=\"currentColor\" stroke-width=\"var(--icon-stroke-width)\"></path><path d=\"M23.456 30.976L26.848 33.76L32.432 27.248\" stroke=\"currentColor\" stroke-width=\"var(--icon-stroke-width)\" stroke-linecap=\"round\" stroke-linejoin=\"round\"></path><path d=\"M36.944 31.632H56.144\" stroke=\"currentColor\" stroke-width=\"var(--icon-stroke-width)\" stroke-linecap=\"round\"></path><path d=\"M23.456 44.688L26.848 47.472L32.432 40.96\" stroke=\"currentColor\" stroke-width=\"var(--icon-stroke-width)\" stroke-linecap=\"round\" stroke-linejoin=\"round\"></path><path d=\"M36.944 45.344H56.144\" stroke=\"currentColor\" stroke-width=\"var(--icon-stroke-width)\" stroke-linecap=\"round\"></path><path d=\"M23.456 58.4L26.848 61.184L32.432 54.672\" stroke=\"currentColor\" stroke-width=\"var(--icon-stroke-width)\" stroke-linecap=\"round\" stroke-linejoin=\"round\"></path><path d=\"M36.944 59.056H56.144\" stroke=\"currentColor\" stroke-width=\"var(--icon-stroke-width)\" stroke-linecap=\"round\"></path>", 9)
  ])))
}
export default { render: render }