import dayjs from 'dayjs'

const URLS_TO_CACHE_24_HOURS = [
  /plans\/.*\/revisions\/.*\/pdf\?contentDisposition=attachment.*/,
  /plans\/.*\/revisions\/.*\/dwg\?contentDisposition=attachment.*/,
  /documents\/.*\/revisions\/.*\/?contentDisposition=attachment.*/,
  /documents\/.*\/attachments\/.*\?contentDisposition=attachment.*/,
  /tasks\/.*\/files\/.*\?contentDisposition=attachment.*/,
  /tasks\/.*\/files\/.*\?contentDisposition=inline.*/,
  /tasks\/.*\/comments\/.*\/files\/.*\?contentDisposition=attachment.*/,
  /tasks\/.*\/comments\/.*\/files\/.*\?contentDisposition=inline.*/,
  /pf-files\/.*\/signed-download-url\?contentDisposition=attachment.*/,
  /pf-files\/.*\/signed-download-url\?contentDisposition=inline.*/,
]

export default ({ $axios }) => {
  $axios.interceptors.request.use(async (config) => {
    const isUrlToCache = URLS_TO_CACHE_24_HOURS.find(regex => regex.test(config.url))

    config.params = config.params || {}

    if (config.method === 'get' && isUrlToCache) {
    /**
      * ATTENTION: We don't do the cache handling for Cypress tests right now.
      * Cypress clears the browser cache only between files, not between tests.
      * This means, that if we are intercepting download requests, this can lead to bugs where we wait for requests which are actually cached.
      */
      if (window.Cypress) {
        config.params.cache_expiry = new Date()
      } else {
        config.params.cache_expiry = dayjs().utc().endOf('day').toDate().getTime()
      }
    } else if (config.method === 'get') {
    // set cache_expiry query parameter on all other GET requests
      config.params.cache_expiry = Date.now()
    }

    return config
  })
}
