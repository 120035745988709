import { getProjects } from '@client-shared/api/projects.api.js'
import Project from '@client-shared/models/project.model.js'
import filterFunctions from '@client-shared/utils/filter-functions'
import { sortAlphabeticallyCaseInsensitive } from '@client-shared/utils/sort-functions.js'

import constants from '@/config/constants'

const fetchAbortControllers = {}

export default {
  namespaced: true,

  state: () => {
    return {
      list: [],
      isLoading: false,
      sortProperty: constants.SORT_PROPERTIES.PROJECT_TITLE_ASC,
    }
  },

  getters: {
    projectFavorites (state, _getters, rootState) {
      const currentUserFavoriteProjectIds = Array.from(rootState.currentUser.user.favorites.projectIds)
      return currentUserFavoriteProjectIds.reduce((acc, pid) => {
        const found = state.list.find(p => p._id === pid)
        return !found ? acc : acc.concat([found])
      }, [])
    },
    projectFavoriteIds: (_state, getters) => {
      return getters.projectFavorites.map((p) => p._id)
    },
    projectsParticipating: (state, _getters, rootState) => {
      const currentUser = rootState.currentUser.user

      if (!currentUser) {
        return []
      }

      return state.list
        .filter(p => {
          const participant = p.findParticipantByUserId(currentUser._id)
          if (!participant) {
            return false
          }
          return participant.permission !== 'owner'
        })
        .sort((a, b) => sortAlphabeticallyCaseInsensitive(a.title, b.title))
    },

    projectsOwning: (state, _getters, rootState) => {
      const currentUser = rootState.currentUser.user

      if (!currentUser) {
        return []
      }

      return state.list
        .filter(p => {
          const participant = p.findParticipantByUserId(currentUser._id)
          if (!participant) {
            return false
          }
          return participant.permission === 'owner'
        })
        .sort((a, b) => sortAlphabeticallyCaseInsensitive(a.title, b.title))
    },

    projectsOwningFiltered: (_state, getters, rootState) => {
      const searchString = rootState.projectsFilter.searchString

      return getters.projectsOwning.filter(project => {
        return filterFunctions.projects.matchesFilterText({
          searchString,
          project,
        })
      })
    },

    projectsParticipatingFiltered: (_state, getters, rootState) => {
      const searchString = rootState.projectsFilter.searchString

      return getters.projectsParticipating.filter(project => {
        return filterFunctions.projects.matchesFilterText({
          searchString,
          project,
        })
      })
    },
  },

  mutations: {
    SET_PROJECTS (state, projects) {
      const areAllProjectsUsingProjectModel = projects.every(project => project instanceof Project)

      if (!areAllProjectsUsingProjectModel) {
        throw new Error('All projects must be instances of Project')
      }

      state.list = projects
    },

    SET_IS_LOADING (state, isLoading) {
      state.isLoading = isLoading
    },

    SET_SORT_PROPERTY (state, sortProperty) {
      state.sortProperty = sortProperty
    },
  },

  actions: {
    async fetch ({ commit, state }) {
      commit('SET_IS_LOADING', true)

      try {
        if (fetchAbortControllers.fetch instanceof AbortController) {
          fetchAbortControllers.fetch.abort()
        }

        fetchAbortControllers.fetch = new AbortController()

        const projects = await getProjects({
          axios: this.$axios,
          signal: fetchAbortControllers.fetch.signal,
        })

        if (projects) {
          commit('SET_PROJECTS', projects)
          commit('SET_IS_LOADING', false)
        }
      } catch (error) {
        commit('SET_IS_LOADING', false)
      }
    },
  },
}
