import { createHead, VueHeadMixin } from '@unhead/vue/client'
import { createApp } from 'vue'
import VueDraggable from 'vuedraggable'

import App from '@/App.vue'
import PfIcon from '@/components/PfIcon.vue'
import analytics from '@/plugins/analytics.js'
import auth from '@/plugins/auth'
import axiosPlugin from '@/plugins/axios'
import broadcastChannel from '@/plugins/broadcast-channel.js'
import chunkReload from '@/plugins/chunk-reload.js'
import clipboardUppy from '@/plugins/clipboard-uppy.js'
import configInject from '@/plugins/config-inject'
import dayjs from '@/plugins/dayjs'
import enableTextSelection from '@/plugins/directives/enable-text-selection'
import i18n from '@/plugins/i18n'
import localStorageListener from '@/plugins/localstorage-listener.js'
import localStorageMigrations from '@/plugins/localstorage-migrations.js'
import logger from '@/plugins/logger.js'
import pfFiles from '@/plugins/pf-files.js'
import raygun from '@/plugins/raygun.js'
import scrollToError from '@/plugins/scroll-to-error'
import scrollToTop from '@/plugins/scroll-to-top'
import socketIo from '@/plugins/socket-io.js'
import unsavedChanges from '@/plugins/unsaved-changes.js'
import wait from '@/plugins/wait'
import router from '@/router'
import $store from '@/store/index.js'

import 'reset-css/reset.css'
import '@/assets/scss/main.scss'

import 'swiper/css'
import 'swiper/css/thumbs'
import 'swiper/css/navigation'

const app = createApp(App)

// enable to debug performance issues with vue developer tools
// app.config.performance = true

const head = createHead()

app
  .use(configInject)
  .use($store)
  .use(i18n)
  .use(router)
  .use(localStorageMigrations)
  .use(axiosPlugin)
  .use(auth)
  .use(logger)
  .use(wait)
  .use(dayjs)
  .use(socketIo)
  .use(clipboardUppy)
  .use(broadcastChannel)
  .use(enableTextSelection)
  .use(analytics)
  .use(scrollToError)
  .use(scrollToTop)
  .use(localStorageListener)
  .use(unsavedChanges)
  .use(head)
  .use(raygun)
  .use(chunkReload)
  .use(pfFiles)
  .mixin(VueHeadMixin) // make head function in pages work like in nuxt 2
  .component('PfIcon', PfIcon)
  .component('VueDraggable', VueDraggable)

// We expose the app instance to the window object ONLY IN CYPRESS, so we can override the config
if (window.Cypress) {
  window.__pf_cypress_vue_instance_config__ = app.config.globalProperties.$config

  // hacky removal of vue dev tools in cypress
  if (window.document.getElementById('vue-inspector-container')) {
    window.document.getElementById('vue-inspector-container')?.remove()
    window.document.getElementById('__vue-devtools-container__')?.remove()
  }
}

app.mount('#app')
