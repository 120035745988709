import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  width: "80",
  height: "80",
  viewBox: "0 0 80 80",
  fill: "none",
  xmlns: "http://www.w3.org/2000/svg"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createElementVNode("path", {
      d: "M71.096 11H8.50401C7.01063 11 5.8 12.2106 5.8 13.704V66.696C5.8 68.1894 7.01063 69.4 8.50401 69.4H71.096C72.5894 69.4 73.8 68.1894 73.8 66.696V13.704C73.8 12.2106 72.5894 11 71.096 11Z",
      stroke: "currentColor",
      "stroke-width": "var(--icon-stroke-width)"
    }, null, -1),
    _createElementVNode("path", {
      d: "M5 21.264H74.6",
      stroke: "currentColor",
      "stroke-width": "var(--icon-stroke-width)"
    }, null, -1),
    _createElementVNode("path", {
      d: "M63.16 48.92C65.1482 48.92 66.76 47.3083 66.76 45.32C66.76 43.3318 65.1482 41.72 63.16 41.72C61.1718 41.72 59.56 43.3318 59.56 45.32C59.56 47.3083 61.1718 48.92 63.16 48.92Z",
      stroke: "currentColor",
      "stroke-width": "var(--icon-stroke-width)"
    }, null, -1),
    _createElementVNode("path", {
      d: "M5.896 45.32H22.04L26.576 55.04L36.296 28.392L42.776 62.816L51.28 45.32H59.344",
      stroke: "currentColor",
      "stroke-width": "var(--icon-stroke-width)",
      "stroke-linejoin": "round"
    }, null, -1)
  ])))
}
export default { render: render }