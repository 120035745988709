<template>
  <header
    data-testid="header-bar"
    class="header-container-fixed"
    :class="{
      'header-container-fixed--overlap': isPageScrolled,
    }"
  >
    <PromotionElementSection
      v-if="$auth.loggedIn"
      data-testid="promotion-element-section"
    />

    <div class="header-bar">
      <div class="header-bar__logo">
        <div
          data-testid="header-logo"
          class="app-logo app-logo--mono"
        >
          <router-link
            v-if="$auth.loggedIn"
            class="header-bar__homelink"
            data-testid="planfred-logo"
            :to="{ name: 'projects' }"
          >
            <PlanfredLogoMono />
          </router-link>
        </div>
      </div>

      <div
        class="header-bar__project-nav"
      >
        <ul
          class="top-nav top-nav--align-center"
        >
          <li
            class="top-nav__item"
          >
            <router-link
              class="top-nav__link top-nav__link--colored"
              :to="{ name: 'projects' }"
              data-testid="projects-list-link-in-header"
            >
              <span
                class="top-nav__link__label"
              >
                {{ $t('layout.header.nav.project_list') }} ({{ projectCount }})
              </span>
            </router-link>

            <ul
              v-if="$route.name.startsWith('projects-projectId')"
            >
              <li
                class="top-nav__item top-nav__item--project-active"
                @click="onClickProjectName"
              >
                <PfIcon
                  name="chevronRight"
                  size="sm"
                  class="top-nav__level-separator-icon"
                />

                <router-link
                  class="top-nav__link top-nav__link--active"
                  :to="{
                    name: 'projects-projectId',
                    params: { projectId },
                  }"
                  data-testid="project-link-in-header"
                >
                  <span
                    class="top-nav__link__label"
                  >
                    {{ projectName }}
                  </span>
                </router-link>
              </li>
            </ul>
          </li>
        </ul>
      </div>

      <div
        class="header-bar__auth-nav"
      >
        <ul
          class="top-nav top-nav--align-right"
        >
          <li
            class="top-nav__item top-nav__item--help"
          >
            <a
              class="top-nav__link"
              data-testid="help-button-in-header"
              @click="onClickSupportBarLink"
            >
              <PfIcon
                name="help"
                :is-big-on-mobile="true"
                class="top-nav__link__icon"
              />

              <span
                class="top-nav__link__label"
              >
                {{ $t('layout.header.nav.help') }}
              </span>
            </a>
          </li>

          <li
            v-if="$auth.loggedIn"
            class="top-nav__item top-nav__item--account"
          >
            <router-link
              :to="{ name: 'account' }"
              class="top-nav__link"
              data-testid="go-to-account-page"
            >
              <PfIcon
                name="userAccount"
                :is-big-on-mobile="true"
                class="top-nav__link__icon"
              />

              <span class="top-nav__link__label">
                <span class="account-text">
                  {{ $t('layout.header.nav.user_account') }}
                </span>

                <span class="account-email">
                  <!-- no space hack -->{{ emailToUnicode($auth.user.email) }}<!-- no space hack -->
                </span>
              </span>
            </router-link>
          </li>

          <LanguageSwitch
            data-testid="language-switch-header"
            class="
              top-nav__item
              com_language-menu--header
              uti_hide-mobile
            "
          />

          <li
            v-if="$auth.loggedIn"
            class="top-nav__item"
          >
            <button
              data-testid="logout-button"
              class="top-nav__button obj_button"
              :class="{
                'is_loading': state === validStates.LOGOUT_PENDING,
              } "
              @click="onClickLogout"
            >
              <span class="label">
                {{ $t('layout.header.nav.logout') }}
              </span>
            </button>
          </li>
        </ul>
      </div>
    </div>

    <HeaderBarSupportBar
      v-if="$store.state.layout.isSupportBarOpen"
      data-testid="header-bar-support-bar"
      @after:close-animation="onClickSupportBarClose"
    />
  </header>
</template>

<script>
import emailToUnicode from '@client-shared/utils/email-to-unicode.js'

import PlanfredLogoMono from '@/assets/img/logo-planfred-mono.svg?component'
import HeaderBarSupportBar from '@/components/HeaderBarSupportBar.vue'
import LanguageSwitch from '@/components/LanguageSwitch.vue'
import PromotionElementSection from '@/components/PromotionElementSection.vue'

export default {
  name: 'HeaderBar',

  components: {
    LanguageSwitch,
    PlanfredLogoMono,
    HeaderBarSupportBar,
    PromotionElementSection,
  },

  data () {
    const validStates = Object.freeze({
      INITIAL: 1,
      LOGOUT_PENDING: 2,
    })

    return {
      validStates,
      state: validStates.INITIAL,
      scrollPosition: null,
    }
  },

  computed: {
    projectName () {
      const project = this.$store.state.project.project

      if (!project) {
        return
      }

      return project.title
    },

    projectId () {
      const project = this.$store.state.project.project

      if (!project) {
        return
      }

      return project._id
    },

    isPageScrolled () {
      return this.scrollPosition > 25
    },

    projectCount () {
      return this.$store.state.projects.list.length
    },
  },

  mounted () {
    window.addEventListener('scroll', this.updateScroll)
  },

  methods: {
    emailToUnicode,

    async onClickLogout () {
      this.state = this.validStates.LOGOUT_PENDING

      /*
      * check for running uploads
      */
      const currentUploads = this.$clipboardUppy.getState().currentUploads || {}
      const hasRunningUploads = Object.keys(currentUploads).length
      //
      //
      if (hasRunningUploads) {
        if (!window.confirm(this.$t('feature.project_header.clipboard.running_uploads_logout_anyways'))) {
          this.state = this.validStates.INITIAL
          return
        }

        this.$clipboardUppy.destroy()
      }

      await this.$auth.logout({
        redirectTarget: {
          name: 'login',
        },
      })
    },

    onClickSupportBarLink () {
      this.$ga.event({
        eventCategory: 'toggleSupportbar',
        eventAction: 'toggleSupportbar in header-bar',
        eventLabel: '',
      })

      this.$store.commit('layout/TOGGLE_SUPPORT_BAR')
    },

    onClickSupportBarClose () {
      this.$store.commit('layout/CLOSE_SUPPORT_BAR')
    },

    onClickProjectName () {
      this.$ga.event({
        eventCategory: 'Project | Link in TopNav',
        eventAction: 'Button | click',
        eventLabel: 'Click',
      })
    },

    updateScroll () {
      this.scrollPosition = window.scrollY
    },
  },
}
</script>
