import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createStaticVNode as _createStaticVNode } from "vue"

const _hoisted_1 = {
  width: "80",
  height: "80",
  viewBox: "0 0 80 80",
  fill: "none",
  xmlns: "http://www.w3.org/2000/svg"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createStaticVNode("<path d=\"M58.8755 66.0365H64.0919C65.1559 66.0365 66.02 65.1725 66.02 64.1084V8.92815C66.02 7.86407 65.1559 7 64.0919 7H22.8807C21.8166 7 20.9525 7.86407 20.9525 8.92815V13.6485\" stroke=\"currentColor\" stroke-width=\"var(--icon-stroke-width)\"></path><path d=\"M15.5441 13.9445C14.696 13.9445 14 14.6326 14 15.4886V71.4369C14 72.285 14.688 72.9811 15.5441 72.9811H57.5314C58.3794 72.9811 59.0755 72.293 59.0755 71.4369V30.6978H43.8583C43.0902 30.6978 42.3142 30.3138 42.3142 29.1537V13.9445H15.5441Z\" stroke=\"currentColor\" stroke-width=\"var(--icon-stroke-width)\" stroke-linejoin=\"round\"></path><path d=\"M42.5062 13.9445L59.0755 30.5058\" stroke=\"currentColor\" stroke-width=\"var(--icon-stroke-width)\" stroke-linecap=\"round\"></path><path d=\"M22.0086 23.4413H36.0097\" stroke=\"currentColor\" stroke-width=\"var(--icon-stroke-width)\" stroke-linecap=\"round\"></path><path d=\"M22.0086 30.6418H36.0097\" stroke=\"currentColor\" stroke-width=\"var(--icon-stroke-width)\" stroke-linecap=\"round\"></path><path d=\"M22.0086 43.0828H51.4509\" stroke=\"currentColor\" stroke-width=\"var(--icon-stroke-width)\" stroke-linecap=\"round\"></path><path d=\"M22.0086 57.4839H51.4509\" stroke=\"currentColor\" stroke-width=\"var(--icon-stroke-width)\" stroke-linecap=\"round\"></path><path d=\"M22.0086 50.2833H38.5699\" stroke=\"currentColor\" stroke-width=\"var(--icon-stroke-width)\" stroke-linecap=\"round\"></path><path d=\"M44.2503 50.2833H51.4509\" stroke=\"currentColor\" stroke-width=\"var(--icon-stroke-width)\" stroke-linecap=\"round\"></path><path d=\"M34.8896 64.6844H51.4509\" stroke=\"currentColor\" stroke-width=\"var(--icon-stroke-width)\" stroke-linecap=\"round\"></path><path d=\"M22.0086 64.6844H29.2092\" stroke=\"currentColor\" stroke-width=\"var(--icon-stroke-width)\" stroke-linecap=\"round\"></path>", 11)
  ])))
}
export default { render: render }