export default {
  namespaced: true,

  state: () => {
    return {
      // ATTENTION: State defaults should be in sync with the filters.model on the server

      searchString: '',
      types: [],
      states: [],
      assigneeUserIds: [],
      assigneeRoles: [],
      creatorUserIds: [],
      creatorRoles: [],
      tags: [],
      dueDateFrom: undefined,
      dueDateTo: undefined,
      dueDatePreset: undefined,
      location1: [],
      location2: [],
      location3: [],
      location4: [],
      locationLayerIds: [],
      tagsAndSwitchActive: true,
      lastLocalUpdate: null,
      lastServerUpdate: null,
    }
  },

  getters: {
    isFilterActive: (state, getters) => {
      return state.searchString ||
       state.types.length > 0 ||
       state.states.length > 0 ||
       state.tags.length > 0 ||
       state.assigneeUserIds.length > 0 ||
       state.assigneeRoles.length > 0 ||
       state.creatorUserIds.length > 0 ||
       state.creatorRoles.length > 0 ||
       state.dueDateFrom ||
       state.dueDateTo ||
       state.dueDatePreset ||
       state.locationLayerIds.length > 0 ||
       getters.isLocationPropertyFilterActive
    },

    activeFilterCount: (state, getters, rootState) => {
      let result = 0

      if (state.searchString.length) { result++ }
      if (state.types.length) { result++ }
      if (state.states.length) { result++ }
      if (state.tags.length) { result++ }
      if (state.assigneeUserIds.length || state.assigneeRoles.length) { result++ }
      if (state.creatorUserIds.length || state.creatorRoles.length) { result++ }
      if (state.dueDateFrom || state.dueDateTo || state.dueDatePreset) { result++ }
      if (state.locationLayerIds.length) { result++ }
      if (getters.isLocationPropertyFilterActive) { result++ }

      return result
    },

    isLocationPropertyFilterActive: (state, getters, rootState, rootGetters) => {
      const locationPropertiesSortedEnabled = rootGetters['project/locationPropertiesSortedEnabled']

      return locationPropertiesSortedEnabled.some(locationProperty => state[locationProperty].length > 0)
    },
  },

  mutations: {
    SET_FILTERS (state, { filters, lastServerUpdate }) {
      state.searchString = filters.searchString || ''
      state.types = [...filters.types]
      state.states = [...filters.states]
      state.tags = [...filters.tags]
      state.tagsAndSwitchActive = filters.tagsAndSwitchActive === undefined ? true : filters.tagsAndSwitchActive
      state.assigneeUserIds = [...filters.assigneeUserIds]
      state.assigneeRoles = [...filters.assigneeRoles]
      state.creatorUserIds = [...filters.creatorUserIds]
      state.creatorRoles = [...filters.creatorRoles]
      state.dueDateFrom = filters.dueDateFrom || undefined
      state.dueDateTo = filters.dueDateTo || undefined
      state.dueDatePreset = filters.dueDatePreset || undefined
      state.location1 = [...filters.location1]
      state.location2 = [...filters.location2]
      state.location3 = [...filters.location3]
      state.location4 = [...filters.location4]
      state.locationLayerIds = [...filters.locationLayerIds]

      if (lastServerUpdate) {
        state.lastLocalUpdate = null
        state.lastServerUpdate = new Date(lastServerUpdate)
      } else {
        state.lastLocalUpdate = new Date()
        state.lastServerUpdate = null
      }
    },

    // used within a project
    RESET_FILTERS (state) {
      state.searchString = ''
      state.types = []
      state.states = []
      state.tags = []
      state.assigneeUserIds = []
      state.assigneeRoles = []
      state.creatorUserIds = []
      state.creatorRoles = []
      state.tagsAndSwitchActive = true
      state.dueDateFrom = undefined
      state.dueDateTo = undefined
      state.dueDatePreset = undefined
      state.location1 = []
      state.location2 = []
      state.location3 = []
      state.location4 = []
      state.locationLayerIds = []

      state.lastLocalUpdate = new Date()
    },

    // used when project changes
    FULL_RESET (state) {
      state.searchString = ''
      state.types = []
      state.states = []
      state.tags = []
      state.assigneeUserIds = []
      state.assigneeRoles = []
      state.creatorUserIds = []
      state.creatorRoles = []
      state.tagsAndSwitchActive = true
      state.dueDateFrom = undefined
      state.dueDateTo = undefined
      state.dueDatePreset = undefined
      state.location1 = []
      state.location2 = []
      state.location3 = []
      state.location4 = []
      state.locationLayerIds = []

      state.lastLocalUpdate = null
      state.lastServerUpdate = null
    },
  },
}
