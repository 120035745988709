<template>
  <div
    class="com_button-bar"
    :class="{
      'com--lg': size === 'lg',
      'com--sm': size === 'sm',
      'com_button-bar--align-left': align === 'left',
      'com_button-bar--align-center': align === 'center',
      'com_button-bar--border-none': !hasBorder,
      'com_button-bar--inverse': isInversed,
    }"
  >
    <div
      v-if="$slots['alt-action']"
      class="com_button-bar__alt-action-slot"
    >
      <slot name="alt-action" />
    </div>

    <div
      v-if="$slots['default']"
      class="com_button-bar__action-slot"
    >
      <slot />
    </div>

    <div
      class="com_button-bar__alert-slot"
    >
      <slot name="error" />
    </div>
  </div>
</template>

<script>
export default {
  name: 'PfButtonBar',

  props: {
    size: {
      type: String,
      default: '',
    },

    hasBorder: {
      type: Boolean,
      default: true,
    },

    align: {
      type: String,
      default: '',
    },

    isInversed: {
      type: Boolean,
      default: false,
    },
  },
}
</script>
