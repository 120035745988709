const getResponseData = response => {
  if (!response || !response?.data) {
    return
  }

  return response.data
}

export {
  getResponseData,
}
