import {
  sortPhotosByCreatedDesc,
  sortPhotosByModifiedDesc,
  sortPhotosByCapturedAtAsc,
  sortPhotosByCapturedAtDesc,
  sortPhotosBySelected,
} from './sort-functions.js'
import constants from '../config/constants.js'

export default ({ sortProperty, project, selectedPhotoIds = [] }) => {
  if (sortProperty === constants.SORT_PROPERTIES.PHOTO_NEWEST) { return sortPhotosByCapturedAtDesc }
  if (sortProperty === constants.SORT_PROPERTIES.PHOTO_OLDEST) { return sortPhotosByCapturedAtAsc }
  if (sortProperty === constants.SORT_PROPERTIES.PHOTO_MODIFIED) { return sortPhotosByModifiedDesc }
  if (sortProperty === constants.SORT_PROPERTIES.PHOTO_SELECTED) { return sortPhotosBySelected.bind({ selectedPhotoIds }) }

  return sortPhotosByCreatedDesc
}
