import { getResponseData } from '../utils/get-response-data'

export const putFilter = async ({ axios, projectId, filterKey, filterBody }) => {
  const { data } = await axios.put('filters', filterBody, {
    params: {
      projectid: projectId,
      filterkey: filterKey,
    },
  })

  return data
}

export const getFilters = async ({ axios, projectId, filterKeys, signal }) => {
  const response = await axios.get('filters', {
    signal,
    params: {
      projectid: projectId,
      filterkeys: filterKeys.join(','),
    },
  })

  const data = getResponseData(response)
  if (!data) { return }

  return data
}
