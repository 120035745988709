import {
  getPhotos as apiGetPhotos,
  getPhoto as apiGetPhoto,
  updateDescription as apiUpdateDescription,
  updateTrashed as apiUpdateTrashed,
  updateTitle as apiUpdateTitle,
  updateAuthor as apiUpdateAuthor,
  updatePfFileId as apiUpdatePfFileId,
  updateTags as apiUpdateTags,
  updateLocations as apiUpdateLocations,
  updatePhotoAlbum as apiUpdatePhotoAlbum,
} from '@client-shared/api/photos.api.js'
import filterFunctions from '@client-shared/utils/filter-functions'
import getPhotoSortFunction from '@client-shared/utils/get-photo-sort-function'

import constants from '@/config/constants.js'

const fetchAbortControllers = {}

export default {
  namespaced: true,

  state: () => {
    return {
      list: [],
      markedPhotoId: undefined,

      listStyle: constants.PHOTO_LIST_STYLES.GRID_LG, // Valid values: [constants.PHOTO_LIST_STYLES.GRID_LG, constants.PHOTO_LIST_STYLES.GRID_SM]
      showMetadata: false,
      groupBy: constants.PHOTOS_LIST_GROUPING.GROUP_BY_NONE,
      sortProperty: constants.SORT_PROPERTIES.PHOTO_NEWEST,

      // Only relevant for modus where photos can be selected
      selection: {
        selectedPhotoIds: [],
        shouldKeepSelected: true,
      },
    }
  },

  getters: {
    listCountUntrashed: (state, getters) => {
      return state.list
        .filter(photo => !photo.trashed)
        .length
    },

    getById: (state) => {
      return photoId => state.list.find(photo => photo._id === photoId)
    },

    trashedPhotos: (state, getters) => {
      return state.list.filter(photo => photo.trashed)
    },

    listFilteredByModus: (state, getters, rootState, rootGetters) => {
      const modus = rootState.photosListModus.modus

      if (modus === constants.LIST_MODUS.TRASH) {
        return getters.trashedPhotos
      } else if (modus === constants.LIST_MODUS.LIST || modus === constants.LIST_MODUS.PRINT || modus === constants.LIST_MODUS.MULTI_ACTION_EDIT) {
        return state.list.filter(photo => !photo.trashed)
      }

      return []
    },

    listFiltered: (state, getters, rootState, rootGetters) => {
      if (!rootState.photosListModus.filterStoreName) {
        return []
      }

      const modus = rootState.photosListModus.modus

      return getters.listFilteredByModus.filter(photo => {
        if (modus === constants.LIST_MODUS.MULTI_ACTION_EDIT && state.selection.shouldKeepSelected && state.selection.selectedPhotoIds.includes(photo._id)) {
          return true
        }

        return filterFunctions.photos.matchesFilters({
          photo,
          project: rootState.project.project,
          ...rootState[rootState.photosListModus.filterStoreName],
        })
      })
    },

    listFilteredSorted: (state, getters, rootState, rootGetters) => {
      const listFiltered = getters.listFiltered || []

      return [...listFiltered].sort((a, b) => {
        return getters.groupFunction(a, b) || getters.sortFunction(a, b)
      })
    },

    groupFunction: (state, getters, rootState, rootGetters) => {
      if (state.groupBy === constants.PHOTOS_LIST_GROUPING.GROUP_BY_ALBUM) {
        const project = rootState.project.project

        if (project) {
          return (a, b) => {
            const aIndexOf = project.photoAlbums.indexOf(a.album)
            const bIndexOf = project.photoAlbums.indexOf(b.album)

            if (aIndexOf === bIndexOf) { return 0 }

            return aIndexOf > bIndexOf ? 1 : -1
          }
        }
      }

      if (state.groupBy === constants.PHOTOS_LIST_GROUPING.GROUP_BY_LOCATION_LAYER) {
        return (a, b) => {
          if (a.locationLayerId === b.locationLayerId) { return 0 }

          const aLocationLayer = rootGetters['locationLayers/getById'](a.locationLayerId)
          const bLocationLayer = rootGetters['locationLayers/getById'](b.locationLayerId)

          if (aLocationLayer && !bLocationLayer) { return -1 }
          if (!aLocationLayer && bLocationLayer) { return 1 }
          if (aLocationLayer.order === bLocationLayer.order) { return 0 }

          return aLocationLayer.order > bLocationLayer.order ? 1 : -1
        }
      }

      return () => 0
    },

    sortFunction: (state, getters, rootState) => {
      return getPhotoSortFunction({
        sortProperty: state.sortProperty,
        project: rootState.project.project,
        selectedPhotoIds: state.selection.selectedPhotoIds,
      })
    },

    markedPhoto: (state, getters) => {
      if (!state.markedPhotoId) {
        return
      }

      return getters.getById(state.markedPhotoId)
    },
  },

  mutations: {
    SET_PHOTOS (state, photos) {
      state.list = photos
    },

    SET_MARKED_PHOTO_ID (state, photoId) {
      state.markedPhotoId = photoId
    },

    ADD_PHOTO (state, photoToAdd) {
      const existingPhotoIndex = state.list.findIndex(photo => photo._id === photoToAdd._id)

      if (existingPhotoIndex !== -1) {
        const isUpdateNewer = new Date(photoToAdd.modified) > new Date(state.list[existingPhotoIndex].modified)

        if (isUpdateNewer) {
          state.list[existingPhotoIndex] = photoToAdd
        }
      } else {
        state.list.push(photoToAdd)
      }
    },

    REPLACE_PHOTO (state, updatedPhoto) {
      const existingPhotoIndex = state.list.findIndex(photo => photo._id === updatedPhoto._id)

      if (existingPhotoIndex !== -1) {
        const isUpdateNewer = new Date(updatedPhoto.modified) > new Date(state.list[existingPhotoIndex].modified)

        if (isUpdateNewer) {
          state.list[existingPhotoIndex] = updatedPhoto
        }
      }
    },

    RESET_SORTING (state) {
      state.sortProperty = constants.SORT_PROPERTIES.PHOTO_NEWEST
    },

    SET_SORT_PROPERTY (state, sortProperty) {
      state.sortProperty = sortProperty
    },

    SET_SHOW_METADATA (state, newValue) {
      state.showMetadata = newValue
    },

    SET_GROUP_BY (state, groupBy) {
      if (!Object.values(constants.PHOTOS_LIST_GROUPING).includes(groupBy)) {
        console.error(`${groupBy} is not a valid grouping mode for task list`)
        return
      }
      state.groupBy = groupBy
    },

    RESET_VIEW_SETTINGS (state) {
      state.listStyle = constants.PHOTO_LIST_STYLES.GRID_LG
      state.showMetadata = false
      state.groupBy = constants.PHOTOS_LIST_GROUPING.GROUP_BY_NONE
      state.sortProperty = constants.SORT_PROPERTIES.PHOTO_NEWEST
    },

    SET_LIST_STYLE (state, newValue) {
      const validValues = [
        constants.PHOTO_LIST_STYLES.GRID_LG,
        constants.PHOTO_LIST_STYLES.GRID_SM,
      ]

      if (!validValues.includes(newValue)) {
        throw new Error(`Invalid list style: ${newValue}. Allowed values: ${validValues.join(', ')}`)
      }

      state.listStyle = newValue
    },

    ADD_SELECTED_PHOTO_ID (state, photoId) {
      if (!state.selection.selectedPhotoIds.includes(photoId)) {
        state.selection.selectedPhotoIds.push(photoId)
      }
    },

    REMOVE_SELECTED_PHOTO_ID (state, photoId) {
      state.selection.selectedPhotoIds = state.selection.selectedPhotoIds.filter(id => id !== photoId)
    },

    SET_SHOULD_KEEP_SELECTED (state, shouldKeepSelected) {
      state.selection.shouldKeepSelected = shouldKeepSelected
    },

    RESET_SELECTION (state) {
      state.selection = {
        selectedPhotoIds: [],
        shouldKeepSelected: true,
      }
    },

    RESET_PHOTOS (state) {
      state.list = []
    },

    FULL_RESET (state) {
      state.list = []
      state.markedPhotoId = undefined

      state.listStyle = constants.PHOTO_LIST_STYLES.GRID_LG
      state.showMetadata = false
      state.groupBy = constants.PHOTOS_LIST_GROUPING.GROUP_BY_NONE
      state.sortProperty = constants.SORT_PROPERTIES.PHOTO_NEWEST

      state.selection = {
        selectedPhotoIds: [],
        shouldKeepSelected: true,
      }
    },
  },

  actions: {
    async fetch ({ commit }, { projectId }) {
      if (fetchAbortControllers.fetchList instanceof AbortController) {
        fetchAbortControllers.fetchList.abort()
      }

      fetchAbortControllers.fetchList = new AbortController()

      const photos = await apiGetPhotos({
        signal: fetchAbortControllers.fetchList.signal,
        axios: this.$axios,
        projectId,
      })

      if (photos) {
        commit('SET_PHOTOS', photos)
      }
    },

    async fetchById ({ commit }, photoId) {
      if (fetchAbortControllers.fetchById instanceof AbortController) {
        fetchAbortControllers.fetchById.abort()
      }

      fetchAbortControllers.fetchById = new AbortController()

      const photo = await apiGetPhoto({
        signal: fetchAbortControllers.fetchById.signal,
        axios: this.$axios,
        photoId,
      })

      if (photo) {
        commit('ADD_PHOTO', photo)
      }
    },

    async updateDescription ({ commit }, { photo, description }) {
      const updatedPhoto = await apiUpdateDescription({
        axios: this.$axios,
        photo,
        description,
      })

      commit('REPLACE_PHOTO', updatedPhoto)
    },

    async deletePhoto ({ commit }, { photo }) {
      const updatedPhoto = await apiUpdateTrashed({
        axios: this.$axios,
        photo,
        isTrashed: true,
      })

      commit('REPLACE_PHOTO', updatedPhoto)
    },

    async restorePhoto ({ commit, rootGetters }, { photo }) {
      const updatedPhoto = await apiUpdateTrashed({
        axios: this.$axios,
        photo,
        isTrashed: false,
      })

      commit('REPLACE_PHOTO', updatedPhoto)
    },

    async updateAuthor ({ commit }, { photo, newUserId, newRole }) {
      const updatedPhoto = await apiUpdateAuthor({
        axios: this.$axios,
        photo,
        newUserId,
        newRole,
      })

      commit('REPLACE_PHOTO', updatedPhoto)
    },

    async updatePfFileId ({ commit }, { photo, pfFileId }) {
      const updatedPhoto = await apiUpdatePfFileId({
        axios: this.$axios,
        photo,
        pfFileId,
      })

      commit('REPLACE_PHOTO', updatedPhoto)
    },

    async updatePhotoTags ({ commit, dispatch }, { item, newValue }) {
      const updatedPhoto = await apiUpdateTags({
        axios: this.$axios,
        photo: item,
        tags: newValue,
      })
      await dispatch('project/fetch', item.projectId, { root: true })

      commit('REPLACE_PHOTO', updatedPhoto)
    },

    async updatePhotoLocations ({ commit, rootGetters }, { photo, location1, location2, location3, location4, locationLayerId, locationPins }) {
      const updatedPhoto = await apiUpdateLocations({
        axios: this.$axios,
        photo,
        location1,
        location2,
        location3,
        location4,
        locationLayerId,
        locationPins,
      })

      commit('REPLACE_PHOTO', updatedPhoto)
    },

    async updatePhotoAlbum ({ commit }, { photo, album }) {
      const updatedPhoto = await apiUpdatePhotoAlbum({
        axios: this.$axios,
        photo,
        album,
      })

      commit('REPLACE_PHOTO', updatedPhoto)
    },

    async updatePhotoTitle ({ commit }, { photo, title }) {
      const updatedPhoto = await apiUpdateTitle({
        axios: this.$axios,
        photo,
        title,
      })

      commit('REPLACE_PHOTO', updatedPhoto)
    },
  },
}
