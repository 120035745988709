import { getResult as getParticipantsResult } from '@/utils/filter-aggregators/participants'
import { getResult as getPhotosResult } from '@/utils/filter-aggregators/photos'
import { getResult as getPlanDocsResult } from '@/utils/filter-aggregators/plan-docs'
import { getResult as getTasksResult } from '@/utils/filter-aggregators/tasks'

/**
 * ########################################################################################################################################
 * ########################################################################################################################################
 *
 * README: This store is for precalculating filter counters and is-enabled-on-click handling.
 *
 * For performance reason we try to iterate the plans/documents lists as few times as possible.
 * The calculation is very efficient now, although the code is not the most cleanest.
 * Mutating objects by passing the object to a function and do the mutation there is not a good practice,
 * but here it is on purpose. Performance tests did show, that this is the most performant solution.
 *
 * Good read for this topic: https://blog.sapegin.me/all/avoid-mutation/
 *
 * ########################################################################################################################################
 * ########################################################################################################################################
 *
 */
export default {
  namespaced: true,

  getters: {
    plans: (state, getters, rootState, rootGetters) => {
      const project = rootState.project.project
      const plansAll = rootGetters['plans/listFilteredByModus']
      const plansFiltered = rootGetters['plans/listFiltered']

      if (!project) {
        return {}
      }

      return getPlanDocsResult({
        project,
        itemsAll: plansAll,
        itemsFiltered: plansFiltered,
        filterStore: rootState[rootState.plansListModus.filterStoreName],
        itemType: 'plans',
      })
    },

    documents: (state, getters, rootState, rootGetters) => {
      const project = rootState.project.project
      const documentsAll = rootGetters['documents/listFilteredByModus']
      const documentsFiltered = rootGetters['documents/listFiltered']

      if (!project) {
        return {}
      }

      return getPlanDocsResult({
        project,
        itemsAll: documentsAll,
        itemsFiltered: documentsFiltered,
        filterStore: rootState[rootState.documentsListModus.filterStoreName],
        itemType: 'documents',
      })
    },

    tasks: (state, getters, rootState, rootGetters) => {
      const project = rootState.project.project
      const tasksAll = rootGetters['tasks/listFilteredByModus']
      const tasksFiltered = rootGetters['tasks/listFiltered']

      if (!project) {
        return {}
      }

      return getTasksResult({
        project,
        tasksAll,
        tasksFiltered,
        filterStore: rootState[rootGetters['tasksListModus/filterStoreName']],
        itemType: 'tasks',
      })
    },

    photos: (state, getters, rootState, rootGetters) => {
      const project = rootState.project.project
      const photosAll = rootGetters['photos/listFilteredByModus']
      const photosFiltered = rootGetters['photos/listFiltered']

      if (!project) {
        return {}
      }

      return getPhotosResult({
        project,
        photosAll,
        photosFiltered,
        filterStore: rootState[rootState.photosListModus.filterStoreName],
        itemType: 'photos',
      })
    },

    participants: (state, getters, rootState, rootGetters) => {
      const project = rootState.project.project
      const participantsAll = rootGetters['participants/listFilteredByModus']
      const participantsFiltered = rootGetters['participants/listFiltered']

      if (!project) {
        return {}
      }

      return getParticipantsResult({
        project,
        participantsAll,
        participantsFiltered,
        filterStore: rootState[rootState.participantsListModus.filterStoreName],
      })
    },
  },
}
