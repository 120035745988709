export default class PfFile {
  constructor ({ _id, s3Key, fileName, mimeType, size, width, height, created, modified, uploaderUserId, projectId, references, resolutions, imgSrc, isUploading, uri, fileLastModifiedAt } = { }) {
    if (!_id) {
      throw new Error('_id in PfFile must be defined')
    }

    this._id = _id
    this.s3Key = s3Key
    this.fileName = fileName
    this.mimeType = mimeType
    this.size = size
    this.width = width
    this.height = height
    this.created = created
    this.modified = modified
    this.fileLastModifiedAt = fileLastModifiedAt
    this.uploaderUserId = uploaderUserId
    this.projectId = projectId
    this.references = references
    this.resolutions = resolutions

    this.imgSrc = imgSrc // Mostly base64, but can be also a URL
    this.isUploading = isUploading // Only used in Ionic app
    this.uri = uri // Only used in Ionic app
  }
}
