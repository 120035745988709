const template = {
  roles: [
    'Architekt',
    'Bauphysik',
    'Brandschutz',
    'Elektro-Planung',
    'HKLS-Planung',
    'OEBA',
    'Statik',
    'Bauherr',
    'Projektsteuerung',
  ],
  phasetags: [
    'Grundlagen',
    'Vorentwurf',
    'Entwurf',
    'Genehmigung-Einreichung',
    'Ausführungsplan',
    'Detailplan',
    'Schalungsplan',
    'Bewehrungsplan',
    'Montageplan',
    'Vergabeplan',
    'Fertigstellung',
    'Bestandsplan',
  ],

  phasetagsDocuments: [
    'Allgemein',
    'Behörde',
    'Datenblätter',
    'Einreichbeilagen',
    'Fotos',
    'Grundlagen',
    'Gutachten',
    'Listen',
    'Protokolle',
    'Terminplan',
  ],
  tags: {
    plans: [
      'Grundriss',
      'Schnitt',
      'Ansicht',
      'Lageplan',
    ],
    documents: [
      'Baubesprechung',
      'Planungsbesprechung',
      'Türliste',
      'Planliste',
    ],
    tasks: [],
    photos: [],
  },
  topCategoriesPlansEnabled: false,
  topCategoriesPlans: ['Standard'],
  topCategoriesDocumentsEnabled: false,
  topCategoriesDocuments: ['Standard'],
  photoAlbums: ['Allgemein'],

  taskTypes: [
    'ToDo',
    'Mangel',
    'Klärungsbedarf',
  ],

  locations: {
    location1: {
      isEnabled: false,
      label: 'Gebäude',
      order: 0,
      values: [
        'Bauteil 1',
        'Bauteil 2',
        'Bauteil 3',
        'Nebengebäude',
      ],
    },

    location2: {
      isEnabled: true,
      label: 'Stockwerk',
      order: 1,
      values: [
        'UG',
        'EG',
        'OG 1',
        'OG 2',
      ],
    },

    location3: {
      isEnabled: true,
      label: 'Einheit',
      order: 2,
      values: [
        'Top 1',
        'Top 2',
        'Top 3',
        'Top 4',
      ],
    },

    location4: {
      isEnabled: true,
      label: 'Raum',
      order: 3,
      values: [
        'Büro',
        'WC',
        'Zimmer 1',
        'Zimmer 2',
      ],
    },
  },
}

export default template
