import { putFilter } from '@client-shared/api/filters.api'

import constants from '@/config/constants'

const storageSync = (store) => {
  const MS_DELAY_UNTIL_SYNC_TO_SERVER = 300

  const getTasksFilterBody = ({ state, storeName }) => {
    return {
      searchString: state[storeName].searchString,
      types: state[storeName].types,
      states: state[storeName].states,
      tags: state[storeName].tags,
      assigneeUserIds: state[storeName].assigneeUserIds,
      assigneeRoles: state[storeName].assigneeRoles,
      creatorUserIds: state[storeName].creatorUserIds,
      creatorRoles: state[storeName].creatorRoles,
      dueDateFrom: state[storeName].dueDateFrom,
      dueDateTo: state[storeName].dueDateTo,
      dueDatePreset: state[storeName].dueDatePreset,
      tagsAndSwitchActive: state[storeName].tagsAndSwitchActive,
      locationLayerIds: state[storeName].locationLayerIds,
      location1: state[storeName].location1,
      location2: state[storeName].location2,
      location3: state[storeName].location3,
      location4: state[storeName].location4,
    }
  }

  const getParticipantsFilterBody = ({ state, storeName }) => {
    return {
      searchString: state[storeName].searchString,
      roles: state[storeName].roles,
      permissions: state[storeName].permissions,
    }
  }

  const getPlansFilterBody = ({ state, storeName }) => {
    return {
      searchString: state[storeName].searchString,
      approvals: state[storeName].approvals,
      approverUserIds: state[storeName].approverUserIds,
      approverRoles: state[storeName].approverRoles,
      tags: state[storeName].tags,
      phasetags: state[storeName].phasetags,
      authorUserIds: state[storeName].authorUserIds,
      authorRoles: state[storeName].authorRoles,
      topCategories: state[storeName].topCategories,
      tagsAndSwitchActive: state[storeName].tagsAndSwitchActive,
      approvalDateFrom: state[storeName].approvalDateFrom,
      approvalDateTo: state[storeName].approvalDateTo,
      approvalDatePreset: state[storeName].approvalDatePreset,
    }
  }

  const getDocumentsFilterBody = ({ state, storeName }) => {
    return {
      searchString: state[storeName].searchString,
      approvals: state[storeName].approvals,
      permissions: state[storeName].permissions,
      permissionUserIds: state[storeName].permissionUserIds,
      approverUserIds: state[storeName].approverUserIds,
      approverRoles: state[storeName].approverRoles,
      tags: state[storeName].tags,
      phasetags: state[storeName].phasetags,
      authorUserIds: state[storeName].authorUserIds,
      authorRoles: state[storeName].authorRoles,
      topCategories: state[storeName].topCategories,
      tagsAndSwitchActive: state[storeName].tagsAndSwitchActive,
      approvalDateFrom: state[storeName].approvalDateFrom,
      approvalDateTo: state[storeName].approvalDateTo,
      approvalDatePreset: state[storeName].approvalDatePreset,
    }
  }

  const getPhotosFilterBody = ({ state, storeName }) => {
    return {
      searchString: state[storeName].searchString,
      tags: state[storeName].tags,
      tagsAndSwitchActive: state[storeName].tagsAndSwitchActive,
      albums: state[storeName].albums,
      authorRoles: state[storeName].authorRoles,
      authorUserIds: state[storeName].authorUserIds,
      location1: state[storeName].location1,
      location2: state[storeName].location2,
      location3: state[storeName].location3,
      location4: state[storeName].location4,
      locationLayerIds: state[storeName].locationLayerIds,
      hasDescription: state[storeName].hasDescription,
      capturedAtFrom: state[storeName].capturedAtFrom,
      capturedAtTo: state[storeName].capturedAtTo,
    }
  }

  const getActivitiesFilterBody = ({ state, storeName }) => {
    return {
      categories: state[storeName].categories,
      participantRoles: state[storeName].participantRoles,
      participantUserIds: state[storeName].participantUserIds,
      dateRange: state[storeName].dateRange,
      startDate: state[storeName].startDate,
      endDate: state[storeName].endDate,
    }
  }

  const settings = {
    tasksFilterDrafts: { // Key needs to match with actual store name
      filterKey: constants.FILTER_KEYS.TASKS_LIST_WEB_DRAFTS,
      updateTimeout: null,
      getFilterBody: getTasksFilterBody,
    },
    tasksFilterMain: {
      filterKey: constants.FILTER_KEYS.TASKS_LIST_WEB_MAIN,
      updateTimeout: null,
      getFilterBody: getTasksFilterBody,
    },
    tasksFilterDone: {
      filterKey: constants.FILTER_KEYS.TASKS_LIST_WEB_DONE,
      updateTimeout: null,
      getFilterBody: getTasksFilterBody,
    },
    plansFilter: {
      filterKey: constants.FILTER_KEYS.PLANS_LIST_WEB_MAIN,
      updateTimeout: null,
      getFilterBody: getPlansFilterBody,
    },
    documentsFilter: {
      filterKey: constants.FILTER_KEYS.DOCUMENTS_LIST_WEB_MAIN,
      updateTimeout: null,
      getFilterBody: getDocumentsFilterBody,
    },
    photosFilter: {
      filterKey: constants.FILTER_KEYS.PHOTOS_LIST_WEB_MAIN,
      updateTimeout: null,
      getFilterBody: getPhotosFilterBody,
    },
    participantsFilter: {
      filterKey: constants.FILTER_KEYS.PARTICIPANTS_LIST_WEB_MAIN,
      updateTimeout: null,
      getFilterBody: getParticipantsFilterBody,
    },
    activitiesFilter: {
      filterKey: constants.FILTER_KEYS.ACTIVITIES_LIST_WEB,
      updateTimeout: null,
      getFilterBody: getActivitiesFilterBody,
    },
  }

  store.subscribe((mutation, state) => {
    const storeName = mutation.type.split('/')[0]

    if (!settings[storeName]) {
      return
    }

    if (state[storeName].lastLocalUpdate <= state[storeName].lastServerUpdate) {
      return
    }

    if (settings[storeName].updateTimeout) {
      clearTimeout(settings[storeName].updateTimeout)
    }

    if (!state.project.project) {
      return
    }

    const projectId = state.project.project._id

    const filterBody = settings[storeName].getFilterBody({ state, storeName })

    settings[storeName].updateTimeout = setTimeout(async () => {
      await putFilter({
        axios: store.$axios,
        projectId,
        filterKey: settings[storeName].filterKey,
        filterBody,
      })
    }, MS_DELAY_UNTIL_SYNC_TO_SERVER)
  })
}

export default storageSync
