import { getLatestCreatedPhotoByUser } from '@client-shared/api/photos.api'

export default {
  namespaced: true,

  state: () => {
    return {
      isEnabled: false,
      isOpen: false,
      latestCreatedPhotoByUser: undefined,

      features: {
        useTitle: true,
        useDescription: true,
        useAuthor: true,
        useAlbum: true,
        useTags: true,
        useLocation: true,
        useLocationPins: true,
      },
    }
  },

  mutations: {
    SET_LATEST_CREATED_PHOTO_BY_USER (state, task) {
      state.latestCreatedPhotoByUser = task
    },

    SET_IS_ENABLED (state, isEnabled) {
      state.isEnabled = isEnabled
    },

    TOGGLE_IS_ENABLED (state) {
      state.isEnabled = !state.isEnabled
    },

    TOGGLE_IS_OPEN (state) {
      state.isOpen = !state.isOpen
    },

    SET_FEATURE_TITLE (state, value) {
      state.features.useTitle = value
    },

    SET_FEATURE_DESCRIPTION (state, value) {
      state.features.useDescription = value
    },

    SET_FEATURE_AUTHOR (state, value) {
      state.features.useAuthor = value
    },

    SET_FEATURE_ALBUM (state, value) {
      state.features.useAlbum = value
    },

    SET_FEATURE_TAGS (state, value) {
      state.features.useTags = value
    },

    SET_FEATURE_LOCATION (state, value) {
      state.features.useLocation = value
    },

    SET_FEATURE_LOCATION_PINS (state, value) {
      state.features.useLocationPins = value
    },

    FULL_RESET (state) {
      state.latestCreatedPhotoByUser = undefined
    },
  },

  actions: {
    async fetchLatestCreatedPhotoByUser ({ commit }, { projectId }) {
      const task = await getLatestCreatedPhotoByUser({
        axios: this.$axios,
        projectId,
      })

      commit('SET_LATEST_CREATED_PHOTO_BY_USER', task)
    },

  },
}
