export default {
  install (app) {
    const $router = app.config.globalProperties.$router
    const $auth = app.config.globalProperties.$auth

    const $pfFiles = {
      fetch: async (projectId) => {
        app.config.globalProperties.$store.dispatch('pfFiles/fetch', projectId)
      },
    }

    /**
     * Use afterEach instead of beforeEach, because if we fetch the resource in the beforeEach hook, the beforeRoute handler in the _projectId route would reset the list again
     */
    $router.afterEach((to, from) => {
      const toProjectId = to.params.projectId
      const fromProjectId = from.params.projectId

      if (!$auth.loggedIn) {
        return
      }
      if (toProjectId && toProjectId !== fromProjectId) {
        $pfFiles.fetch(toProjectId)
      }
    })

    app.config.globalProperties.$pfFiles = $pfFiles
  },
}
