import Activity from '@client-shared/models/activity.model'
import Photo from '@client-shared/models/photo.model'

import { getResponseData } from '../utils/get-response-data.js'

export const getPhotos = async ({ axios, signal, projectId }) => {
  const response = await axios.get('photos', {
    signal,
    params: {
      projectid: projectId,
    },
  })

  const data = getResponseData(response)
  if (!data) { return }

  // Freeze Objects to prevent data binding on photos which causes slow performance
  const photos = data.list.map(photo => Object.freeze(new Photo(photo)))

  return photos
}

export const getPhoto = async ({ axios, photoId, signal }) => {
  const response = await axios.get(`photos/${photoId}`, { signal })

  const data = getResponseData(response)
  if (!data) { return }

  return new Photo(data)
}

export const getActivitiesFromPhoto = async ({ axios, photoId }) => {
  const { data } = await axios.get(`photos/${photoId}/activities`)

  return data.map(p => new Activity(p))
}

export const getLatestCreatedPhotoByUser = async ({ axios, projectId }) => {
  const { data } = await axios.get('photos/latest-by-user', {
    params: {
      projectid: projectId,
    },
  })

  return data && Object.keys(data).length > 0 ? new Photo(data) : undefined // keys check needed for backup app
}

export const postPhoto = async ({ axios, photo }) => {
  const { data } = await axios.post('photos', photo)

  return new Photo(data)
}

export const getActivities = async ({ axios, photoId }) => {
  const { data } = await axios.get(`photos/${photoId}/activities`)

  return data.map(photo => new Activity(photo))
}

export const updateDescription = async ({ axios, photo, description }) => {
  const patchObject = {
    op: 'update',
    path: 'description',
    value: {
      old: photo.description,
      new: description,
    },
  }

  const { data } = await axios.patch(`photos/${photo._id}`, patchObject)

  return new Photo(data)
}

export const updateTrashed = async ({ axios, photo, isTrashed }) => {
  const patchObject = {
    op: 'update',
    path: 'trashed',
    value: {
      old: photo.trashed,
      new: isTrashed,
    },
  }

  const { data } = await axios.patch(`photos/${photo._id}`, patchObject)

  return new Photo(data)
}

export const updateAuthor = async ({ axios, photo, newUserId, newRole }) => {
  let oldAuthor = {}
  let newAuthor = {}

  if (photo.author.userId) {
    oldAuthor = {
      userId: photo.author.userId,
    }
  } else if (photo.author.role) {
    oldAuthor = {
      role: photo.author.role,
    }
  }
  if (newUserId) {
    newAuthor = {
      userId: newUserId,
    }
  } else if (newRole) {
    newAuthor = {
      role: newRole,
    }
  }

  const patchObject = {
    op: 'update',
    path: 'author',
    value: {
      old: oldAuthor,
      new: newAuthor,
    },
  }

  const { data } = await axios.patch(`photos/${photo._id}`, patchObject)

  return new Photo(data)
}

export const updateTags = async ({ axios, photo, tags }) => {
  const patchObject = {
    op: 'update',
    path: 'tags',
    value: {
      old: photo.tags,
      new: tags,
    },
  }

  const { data } = await axios.patch(`photos/${photo._id}`, patchObject)

  return new Photo(data)
}

export const updateLocations = async ({ axios, photo, location1, location2, location3, location4, locationLayerId, locationPins }) => {
  const patchObject = {
    op: 'update',
    path: 'locations',
    value: {
      old: {
        location1: photo.location1,
        location2: photo.location2,
        location3: photo.location3,
        location4: photo.location4,
        locationLayerId: photo.locationLayerId,
        locationPins: photo.locationPins,
      },
      new: {
        location1,
        location2,
        location3,
        location4,
        locationLayerId,
        locationPins,
      },
    },
  }

  const { data } = await axios.patch(`photos/${photo._id}`, patchObject)

  return new Photo(data)
}

export const updateLocation = async ({ axios, photo, locationProperty, location }) => {
  const patchObject = {
    op: 'update',
    path: locationProperty,
    value: {
      old: photo[locationProperty],
      new: location,
    },
  }

  const { data } = await axios.patch(`photos/${photo._id}`, patchObject)

  return new Photo(data)
}

export const updatePhotoAlbum = async ({ axios, photo, album }) => {
  const patchObject = {
    op: 'update',
    path: 'album',
    value: {
      old: photo.album,
      new: album,
    },
  }

  const { data } = await axios.patch(`photos/${photo._id}`, patchObject)

  return new Photo(data)
}

export const updateTitle = async ({ axios, photo, title }) => {
  const patchObject = {
    op: 'update',
    path: 'title',
    value: {
      old: photo.title,
      new: title,
    },
  }

  const { data } = await axios.patch(`photos/${photo._id}`, patchObject)

  return new Photo(data)
}

export const updatePfFileId = async ({ axios, photo, pfFileId }) => {
  const patchObject = {
    op: 'update',
    path: 'pfFileId',
    value: {
      old: photo.pfFileId,
      new: pfFileId,
    },
  }

  const { data } = await axios.patch(`photos/${photo._id}`, patchObject)

  return new Photo(data)
}
