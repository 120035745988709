import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createStaticVNode as _createStaticVNode } from "vue"

const _hoisted_1 = {
  width: "80",
  height: "80",
  viewBox: "0 0 80 80",
  fill: "none",
  xmlns: "http://www.w3.org/2000/svg"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createStaticVNode("<path d=\"M11.9844 67.6435H73.38V14.9924H17.9447\" stroke=\"currentColor\" stroke-width=\"var(--icon-stroke-width)\" stroke-linejoin=\"round\"></path><path d=\"M17.9767 14.9924C17.9767 11.6882 15.2965 9 11.9844 9C8.67216 9 6 11.6802 6 14.9924\" stroke=\"currentColor\" stroke-width=\"var(--icon-stroke-width)\"></path><path d=\"M17.9287 60.8751C17.5447 57.9389 15.0325 55.6667 11.9923 55.6667C8.68815 55.6667 6.008 58.3469 6.008 61.6511C6.008 64.9553 8.68815 67.6355 11.9923 67.6355\" stroke=\"currentColor\" stroke-width=\"var(--icon-stroke-width)\" stroke-linecap=\"round\"></path><path d=\"M17.9767 14.6404V60.9471\" stroke=\"currentColor\" stroke-width=\"var(--icon-stroke-width)\" stroke-linecap=\"round\"></path><path d=\"M6 14.9924V61.6511\" stroke=\"currentColor\" stroke-width=\"var(--icon-stroke-width)\" stroke-linejoin=\"round\"></path><path d=\"M42.3782 24.6569H25.5772V41.4579H42.3782V24.6569Z\" stroke=\"currentColor\" stroke-width=\"var(--icon-stroke-width)\" stroke-linejoin=\"round\"></path><path d=\"M66.7236 49.7944H49.9226V56.9949H66.7236V49.7944Z\" stroke=\"currentColor\" stroke-width=\"var(--icon-stroke-width)\" stroke-linejoin=\"round\"></path><path d=\"M58.3231 41.4579C62.9626 41.4579 66.7236 37.6969 66.7236 33.0574C66.7236 28.418 62.9626 24.6569 58.3231 24.6569C53.6836 24.6569 49.9226 28.418 49.9226 33.0574C49.9226 37.6969 53.6836 41.4579 58.3231 41.4579Z\" stroke=\"currentColor\" stroke-width=\"var(--icon-stroke-width)\"></path><path d=\"M42.3782 50.5945H25.5772\" stroke=\"currentColor\" stroke-width=\"var(--icon-stroke-width)\" stroke-linecap=\"round\"></path><path d=\"M42.3782 56.1948H25.5772\" stroke=\"currentColor\" stroke-width=\"var(--icon-stroke-width)\" stroke-linecap=\"round\"></path>", 10)
  ])))
}
export default { render: render }