import PfFile from '@client-shared/models/pf-file.model'

import { getResponseData } from '../utils/get-response-data.js'

export const getPfFiles = async ({ axios, signal, projectId, from }) => {
  const response = await axios.get('pf-files', {
    signal,
    params: {
      projectid: projectId,
      from,
    },
  })

  const data = getResponseData(response)
  if (!data) { return }

  // Freeze Objects to prevent data binding on pfFiles which causes slow performance
  const pfFiles = data.list.map(pfFile => Object.freeze(new PfFile(pfFile)))

  return pfFiles
}

export const postPfFile = async ({ axios, _id, projectId, fileLastModifiedAt, s3Key, fileName, mimeType, size, width, height }) => {
  const { data } = await axios.post('pf-files', {
    _id,
    s3Key,
    fileLastModifiedAt,
    fileName,
    mimeType,
    size,
    width,
    height,
  }, {
    params: {
      projectid: projectId,
    },
  })

  return new PfFile(data)
}

export const getSignedDownloadUrl = async ({ axios, pfFileId, contentDisposition = 'attachment', resolution = '', fileName }) => {
  if (!['attachment', 'inline'].includes(contentDisposition)) {
    throw new Error('Invalid contentDisposition')
  }

  const { data } = await axios.get(`pf-files/${pfFileId}/signed-download-url`,
    {
      params: {
        contentDisposition,
        resolution,
        fileName: fileName ? encodeURIComponent(fileName) : undefined,
      },
    },
  )

  return data.url
}
