import { getPfFiles } from '@client-shared/api/pf-files.api'

const fetchAbortControllers = {}

export default {
  namespaced: true,

  state: () => {
    return {
      list: [],
      lastFetchedAt: undefined,
    }
  },

  getters: {
    getById: (state) => {
      return pfFileId => state.list.find(pfFile => pfFile._id === pfFileId)
    },
  },

  mutations: {
    SET_PF_FILES (state, pfFiles) {
      state.list = pfFiles
    },

    SET_LAST_FETCHED_AT (state, lastFetchedAt) {
      state.lastFetchedAt = lastFetchedAt
    },

    REPLACE_PF_FILE (state, updatedPfFile) {
      const existingPfFileIndex = state.list.findIndex(pfFile => pfFile._id === updatedPfFile._id)

      if (existingPfFileIndex !== -1) {
        const isUpdateNewer = new Date(updatedPfFile.modified) > new Date(state.list[existingPfFileIndex].modified)

        if (isUpdateNewer) {
          state.list[existingPfFileIndex] = updatedPfFile
        }
      }
    },

    ADD_PF_FILE (state, pfFileToAdd) {
      const existingPfFileIndex = state.list.findIndex(pfFile => pfFile._id === pfFileToAdd._id)

      if (existingPfFileIndex !== -1) {
        const isUpdateNewer = new Date(pfFileToAdd.modified) > new Date(state.list[existingPfFileIndex].modified)

        if (isUpdateNewer) {
          state.list[existingPfFileIndex] = pfFileToAdd
        }
      } else {
        state.list.push(pfFileToAdd)
      }
    },

    FULL_RESET (state) {
      state.list = []
      state.lastFetchedAt = undefined
    },
  },

  actions: {
    async fetch ({ commit, state }, projectId) {
      if (fetchAbortControllers.fetchList instanceof AbortController) {
        fetchAbortControllers.fetchList.abort()
      }

      fetchAbortControllers.fetchList = new AbortController()

      const pfFiles = await getPfFiles({
        signal: fetchAbortControllers.fetchList.signal,
        axios: this.$axios,
        projectId,
        from: state.lastFetchedAt,
      })

      if (!pfFiles) {
        return
      }

      commit('SET_LAST_FETCHED_AT', new Date())

      for (const pfFile of pfFiles) {
        commit('ADD_PF_FILE', pfFile)
      }
    },
  },
}
