import filterFunctions from '@client-shared/utils/filter-functions'

import sendRaygun from '@/utils/send-raygun.js'

const getPropertyValue = ({ currentValue, hasResult }) => {
  return {
    count: currentValue ? currentValue.count + 1 : 1,
    hasResult,
  }
}

const setHasResult = (object, value) => {
  for (const key of Object.keys(object)) {
    object[key].hasResult = value
  }
}

const handleAuthorCount = ({ result, project, photo }) => {
  const author = project.findParticipantByPhotoAuthor(photo.author)

  // temporary raygun logging for debugging - can be removed by end of 2024
  if (!author) {
    sendRaygun({
      error: new Error('PROPERTY_ACCESS_ERROR: author.roles[0]'),
      tags: ['property_access_error'],
      customData: {
        author: photo.author,
        project: project._id,
      },
    })

    return
  }

  result.authorRoles[author.roles[0]] = getPropertyValue({
    currentValue: result.authorRoles[author.roles[0]],
    hasResult: false,
  })

  if (photo.author.userId) {
    result.authorUserIds[photo.author.userId] = getPropertyValue({
      currentValue: result.authorUserIds[photo.author.userId],
      hasResult: false,
    })
  }
}

const handleTagsCount = ({ result, photo }) => {
  for (const tag of photo.tags) {
    result.tags[tag] = getPropertyValue({
      currentValue: result.tags[tag],
      hasResult: false,
    })
  }
}

const handleAlbumsCount = ({ result, photo }) => {
  result.albums[photo.album] = getPropertyValue({
    currentValue: result.albums[photo.album],
    hasResult: false,
  })
}

const handleHasDescriptionCount = ({ result, photo }) => {
  const hasDescription = Boolean(photo.description)

  result.hasDescription[hasDescription] = getPropertyValue({
    currentValue: result.hasDescription[hasDescription],
    hasResult: false,
  })
}

const handleLocationsCount = ({ result, photo, locationProperty }) => {
  const locationValue = photo[locationProperty] || null

  result[locationProperty][locationValue] = getPropertyValue({
    currentValue: result[locationProperty][locationValue],
    hasResult: false,
  })
}

const handleLocationLayerIdsCount = ({ result, photo }) => {
  const locationLayerId = photo.locationLayerId || null

  result.locationLayerIds[locationLayerId] = getPropertyValue({
    currentValue: result.locationLayerIds[locationLayerId],
    hasResult: false,
  })
}

const handleAuthorHasResults = ({ result, itemType, project, filterStore, photosAll, isFilterActive }) => {
  if (isFilterActive) {
    const itemsFilteredExcludingAuthor = photosAll.filter(photo => { return filterFunctions[itemType].matchesFilters({ photo, project, ...filterStore, authorRoles: [], authorUserIds: [] }) })

    for (const photo of itemsFilteredExcludingAuthor) {
      const author = project.findParticipantByPhotoAuthor(photo.author)

      result.authorRoles[author.roles[0]].hasResult = true

      if (photo.author.userId) {
        result.authorUserIds[photo.author.userId].hasResult = true
      }
    }
  } else {
    setHasResult(result.authorRoles, true)
    setHasResult(result.authorUserIds, true)
  }
}

const handleTagsHasResults = ({ result, itemType, project, filterStore, photosAll, photosFiltered, isFilterActive }) => {
  if (isFilterActive) {
    let photosFilteredExcludingTags

    if (filterStore.tagsAndSwitchActive) {
      photosFilteredExcludingTags = photosFiltered
    } else {
      photosFilteredExcludingTags = photosAll.filter(photo => { return filterFunctions[itemType].matchesFilters({ photo, project, ...filterStore, tags: [] }) })
    }

    for (const photo of photosFilteredExcludingTags) {
      for (const tag of photo.tags) {
        result.tags[tag] = result.tags[tag] || { hasResult: false, count: 0 }
        result.tags[tag].hasResult = true
      }
    }
  } else {
    setHasResult(result.tags, true)
  }
}

const handleAlbumsHasResults = ({ result, itemType, project, filterStore, photosAll, photosFiltered, isFilterActive }) => {
  if (isFilterActive) {
    const photosFilteredExcludingAlbums = photosAll.filter(photo => { return filterFunctions[itemType].matchesFilters({ photo, project, ...filterStore, albums: [] }) })

    for (const photo of photosFilteredExcludingAlbums) {
      result.albums[photo.album] = result.albums[photo.album] || { hasResult: false, count: 0 }
      result.albums[photo.album].hasResult = true
    }
  } else {
    setHasResult(result.albums, true)
  }
}

const handleHasDescriptionResult = ({ result, project, filterStore, photosAll, photosFiltered, isFilterActive }) => {
  if (isFilterActive) {
    const photoWithDescription = photosAll.find(photo => { return filterFunctions.photos.matchesFilters({ photo, project, ...filterStore, hasDescription: true }) })
    const photoWithoutDescription = photosAll.find(photo => { return filterFunctions.photos.matchesFilters({ photo, project, ...filterStore, hasDescription: false }) })

    result.hasDescription.true = result.hasDescription.true || { hasResult: false, count: 0 }
    result.hasDescription.true.hasResult = Boolean(photoWithDescription)

    result.hasDescription.false = result.hasDescription.false || { hasResult: false, count: 0 }
    result.hasDescription.false.hasResult = Boolean(photoWithoutDescription)
  } else {
    setHasResult(result.hasDescription, true)
  }
}

const handleLocationLayerIdsHasResults = ({ result, project, filterStore, photosAll, isFilterActive }) => {
  if (isFilterActive) {
    const photosFilteredExcludingLocationLayerIds = photosAll.filter(photo => { return filterFunctions.photos.matchesFilters({ photo, project, ...filterStore, locationLayerIds: [] }) })

    for (const photo of photosFilteredExcludingLocationLayerIds) {
      const locationLayerId = photo.locationLayerId || null // Use "null", not "undefined", because "undefined" is not a valid JSON value and gets parsed to null

      result.locationLayerIds[locationLayerId].hasResult = true
    }
  } else {
    setHasResult(result.locationLayerIds, true)
  }
}

export const getResult = ({ photosAll, itemType, project, photosFiltered, filterStore }) => {
  const result = {
    authorRoles: {},
    authorUserIds: {},
    tags: {},
    albums: {},
    location1: {},
    location2: {},
    location3: {},
    location4: {},
    locationLayerIds: {},
    hasDescription: {},
  }

  const isFilterActive = photosAll.length !== photosFiltered.length

  for (const photo of photosAll) {
    handleAuthorCount({ result, photo, project })
    handleTagsCount({ result, photo })
    handleAlbumsCount({ result, photo })
    handleHasDescriptionCount({ result, photo })
    handleLocationLayerIdsCount({ result, photo })

    if (project.locations.location1.isEnabled) {
      handleLocationsCount({ result, photo, locationProperty: 'location1' })
    }
    if (project.locations.location2.isEnabled) {
      handleLocationsCount({ result, photo, locationProperty: 'location2' })
    }
    if (project.locations.location3.isEnabled) {
      handleLocationsCount({ result, photo, locationProperty: 'location3' })
    }
    if (project.locations.location4.isEnabled) {
      handleLocationsCount({ result, photo, locationProperty: 'location4' })
    }
  }

  handleAuthorHasResults({ result, itemType, project, isFilterActive, photosAll, filterStore })
  handleTagsHasResults({ result, project, itemType, isFilterActive, photosAll, photosFiltered, filterStore })
  handleAlbumsHasResults({ result, project, itemType, isFilterActive, photosAll, photosFiltered, filterStore })
  handleLocationLayerIdsHasResults({ result, project, isFilterActive, photosAll, filterStore })
  handleHasDescriptionResult({ result, project, isFilterActive, photosAll, filterStore })

  return result
}
