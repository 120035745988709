import isOnLocalhost from '@client-shared/utils/is-on-localhost'
import axios from 'axios'

export default ({ error, tags, customData }) => {
  if (isOnLocalhost) {
    if (axios.isCancel(error)) {
      console.log('localhost -> Request was aborted.', error.message)
      return
    }

    console.log('sendRaygun', {
      error,
      tags,
      customData,
    })
  }

  if (window.rg4js) {
    if (axios.isCancel(error)) {
      return
    }

    window.rg4js('send', {
      error,
      tags,
      customData,
    })
  }
}
